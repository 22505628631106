import { useState } from "react";
import styles from "./index.module.css";
import SVGClass from "../../../../../../assets/svg";

const TestimonialRender = ({ style, templates }) => {
  const [currentSlider, setCurrentSlider] = useState(1);

  const currentSlide = (index) => {
    setCurrentSlider(index);
  };

  if (templates === "testimonial_1") {
    return (
      <div className="apply_max_width_widget">
        {style.members.map((item, index) =>
          index + 1 === currentSlider ? (
            <div
              key={index}
              className={`${styles.mySlides} ${styles.fade} width_100`}
            >
              <h1 className="text_align_center">{item.testimonial}</h1>
              <div className="d_flex justify_content_center">
                <img
                  className={styles.testimonial_image_1}
                  src={item.image}
                  alt="member"
                  height={100}
                />
              </div>
              <h4 className="text_align_center">{item.name}</h4>
              <p className="text_align_center">{item.designation}</p>
            </div>
          ) : (
            <></>
          )
        )}
        <div className="text_align_center">
          {style.members.map((item, index) => (
            <span
              className={`${styles.testimonial_dot} ${index + 1 === currentSlider && styles.active}`}
              onClick={() => currentSlide(index + 1)}
            ></span>
          ))}
        </div>
      </div>
    );
  } else if (templates === "testimonial_2") {
    return (
      <div className="apply_max_width_widget">
        {style.members.map((item, index) =>
          index + 1 === currentSlider ? (
            <div className="d_flex justify_content_between align_items_center">
              <div className="width_100">
                <img
                  className={styles.testimonial_image_2}
                  src={item.image}
                  alt="member"
                  height={300}
                />
              </div>
              <div className="width_100">
                <h1>{item.testimonial}</h1>
                <h4>{item.name}</h4>
                <p>{item.designation}</p>
              </div>
            </div>
          ) : (
            <></>
          )
        )}
        <div className="text_align_center">
          {style.members.map((item, index) => (
            <span
            className={`${styles.testimonial_dot} ${index + 1 === currentSlider && styles.active}`}
              onClick={() => currentSlide(index + 1)}
            ></span>
          ))}
        </div>
      </div>
    );
  } else if (templates === "testimonial_3") {
    return (
      <div className="apply_max_width_widget">
        <div className="d_flex align_items_center">
          <div className={styles.prev_and_next_btn}>
            <SVGClass.ArrowLeft />
          </div>
          <div className="width_100">
            {style.members.map((item, index) =>
              index + 1 === currentSlider ? (
                <div className="d_flex align_items_center justify_content_between">
                  <div className="d_flex">
                    <img
                      className={styles.testimonial_image_1}
                      src={item.image}
                      alt="member"
                      height={100}
                    />
                    <div className="margin_left_10px">
                      <h4>{item.name}</h4>
                      <p>{item.designation}</p>
                    </div>
                  </div>
                  <div>
                    <h1>{item.testimonial}</h1>
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
          <div className={styles.prev_and_next_btn}>
            <SVGClass.ArrowRight />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="apply_max_width_widget">
          <div>
            <h1>{style.content.title}</h1>
            <p>{style.content.subtitle}</p>
          </div>
          <div className="d_flex flex_wrap">
            {style.members.map((item, index) => (
              <div className={styles.testimonial_4_items}>
                <h2>{item.testimonial}</h2>
                <div>
                  <h4>{item.name}</h4>
                  <p>{item.designation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default TestimonialRender;

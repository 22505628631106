// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.render_logo_item__5-KPm {
    padding: 20px;
    min-height: 104px;
    max-height: 104px;
    min-width: 125px;
    max-width: 125px;
    background-color: white;
    box-shadow: 0px 0px 1px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.render_logo_images__EtS6Y {
    max-height: 100px
}

.render_img_greyscale__9Xoch {
    filter: grayscale(100%);
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/logo_cloud/widgets/render/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,iCAAiC;IACjC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".logo_item {\n    padding: 20px;\n    min-height: 104px;\n    max-height: 104px;\n    min-width: 125px;\n    max-width: 125px;\n    background-color: white;\n    box-shadow: 0px 0px 1px #00000029;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 20px;\n}\n\n.logo_images {\n    max-height: 100px\n}\n\n.img_greyscale {\n    filter: grayscale(100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo_item": `render_logo_item__5-KPm`,
	"logo_images": `render_logo_images__EtS6Y`,
	"img_greyscale": `render_img_greyscale__9Xoch`
};
export default ___CSS_LOADER_EXPORT___;

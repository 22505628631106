export const categoriesConfig = {
  basicComponents: {
    title: "Basic Widgets",
    components: ["HeroSection", "ContactUs", "About", "Gallery", "Teams", "FAQs", "Testimonials"],
    visible: true,
    defaultExpanded: true,
  },
  advancedComponents: {
    title: "Advanced Widgets",
    components: ["LogoCloud", "Features", "Form", "Blog", "Pricing","Services", "Plans"],
    visible: true,
    defaultExpanded: true,
  },
};

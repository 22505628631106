import { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SVGClass from "../../../../../../assets/svg";
import Input from "../../../../../field_objects/image_input/input";
import { useParams } from "react-router-dom";
// import styles from "../../index.module.css";
import { getFileLinksWithSiteId, uploadFile } from "../../../../../../apis";
import { useSelector } from "react-redux";
import Modal from "../../../../../../components/modal";
import { useDispatch } from "react-redux";
import { getFileLinksWithSiteIdThunk } from "../../../../../../redux/thunks";

const LogoCloudField = ({ value, onChange }) => {
  const fileInputRef = useRef(null);
  const stateDispatch = useDispatch();
  const { pageId, siteId } = useParams();
  const [selectedInput, setSelectedInput] = useState(null);
  const [selectedOption, setSelectedOption] = useState("upload");
  const media = useSelector((state) => state.media.media);
  const [imageModal, setImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");

  //   useEffect(() => {
  //     if (value === undefined) {
  //       onChange([]);
  //     }
  //   }, [value, onChange]);

  const handleFileChange = async (index, event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      const uploadImageUrl = await uploadFile(file, siteId);
      // const uploadImageUrl = await uploadFileThunk(file, siteId);
      if (uploadImageUrl) {
        const newInputs = value.map((input, i) =>
          i === index
            ? {
                ...input,
                image: `http://boostify360.b-cdn.net/${uploadImageUrl}`,
              }
            : input
        );
        onChange(newInputs);
        getGalleryMedia();
      }
    }
    setIsLoading(false);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedInputs = Array.from(value.inputs);
    const [removed] = reorderedInputs.splice(result.source.index, 1);
    reorderedInputs.splice(result.destination.index, 0, removed);

    onChange([...reorderedInputs]);
  };

  const descriptionChangeHandler = (index, description) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, description: description } : input
    );
    onChange(newInputs);
  };

  const getGalleryItems = async () => {
    setIsLoading(true);
    await getFileLinksWithSiteId(siteId)
      .then((res) => {
        if (res.success === true) {
          setImageModal(true);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const getGalleryMedia = useCallback(async () => {
    try {
      await stateDispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
    } catch (e) {}
  }, [siteId, stateDispatch]);

  const addingMember = () => {
    const validValue = value;
    if (validValue !== undefined && validValue !== null && validValue) {
      onChange([
        ...validValue,
        {
          id: Math.random(),
          name: "Member",
          designation: "Designation",
          image:
            "https://boostify360.b-cdn.net/NeH365Vbn746OX7ZCid11724453563/1727113558427.png",
        },
      ]);
    } else {
      onChange([
        {
          id: Math.random(),
          name: "Member",
          designation: "Designation",
          image:
            "https://boostify360.b-cdn.net/NeH365Vbn746OX7ZCid11724453563/1727113558427.png",
        },
      ]);
    }
  };

  const onChangeLinkHandler = (e) => {
    setLink(e.target.value);
    onChange(e.target.value);
  };

  const handleImageSelect = async (index, imageUrl) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, image: imageUrl } : input
    );
    onChange(newInputs);
    setImageModal(!imageModal);
  };

  const designationChangeHandler = (index, label) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, designation: label } : input
    );
    onChange(newInputs);
  };

  const nameChangeHandler = (index, label) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, name: label } : input
    );
    onChange(newInputs);
  };

  const deleteChangeHandler = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    const newInputs = value?.filter((input) => input.id !== index);
    onChange(newInputs);
  };

  const selectedItemHandler = (item) => {
    if (item.id === selectedInput?.id) {
      setSelectedInput(null);
    } else {
      setSelectedInput(item);
    }
  };
  return (
    <div>
      <div className="field_heading">Details</div>
      <button
        className="primary_button width_100 d_flex justify_content_center margin_vertical_10px"
        onClick={addingMember}
      >
        Add Image
      </button>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {value &&
                value?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="cursor_pointer"
                      >
                        <div
                          className="gray_border normal_rounded padding_10px margin_vertical_10px d_flex justify_content_between align_items_center"
                          onClick={() => selectedItemHandler(item)}
                        >
                          {item.name}
                          <div onClick={(e) => deleteChangeHandler(item.id, e)}>
                            <SVGClass.Trash />
                          </div>
                        </div>
                        {selectedInput && selectedInput?.id === item.id && (
                          <div className="gray_border normal_rounded padding_10px">
                            <div className="field_heading">Name</div>
                            <div className="field_label_field_input__u5-69">
                              <input
                                className="_Input-input_1qi5b_46 margin_vertical_10px"
                                autoComplete="off"
                                type="text"
                                value={item.name || ""}
                                onChange={(e) =>
                                  nameChangeHandler(index, e.target.value)
                                }
                              />
                            </div>
                            <div className="field_heading">Image</div>
                            <div className="d_block">
                              <div className="field_label margin_vertical_10px">
                                Upload Image
                              </div>
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="d_none"
                                onChange={(e)=>handleFileChange(index,e)}
                                accept="image/*"
                              />
                              <div className="d_flex justify_content_center margin_bottom_10px">
                                <img
                                  className="normal_rounded"
                                  src={
                                    item.image
                                      ? item.image
                                      : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
                                  }
                                  alt="select_Image"
                                  height={80}
                                />
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="upload"
                                    checked={selectedOption === "upload"}
                                    onChange={handleOptionChange}
                                  />
                                  Upload Image
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    value="link"
                                    checked={selectedOption === "link"}
                                    onChange={handleOptionChange}
                                  />
                                  Use Image Link
                                </label>
                              </div>
                              {selectedOption === "upload" ? (
                                <div className="d_flex align_items_center">
                                  {media.length !== 0 && (
                                    <button
                                      onClick={getGalleryItems}
                                      className="secondary_button width_100 d_flex justify_content_center padding_10px margin_right_10px"
                                    >
                                      Choose Image
                                    </button>
                                  )}
                                  <button
                                    onClick={handleClick}
                                    className="secondary_button width_100 d_flex justify_content_center padding_10px"
                                  >
                                    Upload Image
                                  </button>
                                </div>
                              ) : (
                                <div className="field_label_field_input__u5-69">
                                  <input
                                    className="_Input-input_1qi5b_46"
                                    type="text"
                                    value={link}
                                    placeholder="Paste The Link"
                                    onChange={onChangeLinkHandler}
                                  />
                                </div>
                              )}
                              <Modal
                                showModal={imageModal}
                                setShowModal={setImageModal}
                              >
                                <div className="popup_section">
                                  <h4 className="popup_title">Galary Data</h4>
                                  <p
                                    className={`popup_subtitle text_align_center`}
                                  >
                                    Please select Image
                                  </p>
                                  <div
                                    style={{
                                      maxHeight: "50vh",
                                    }}
                                  >
                                    {media.map((image, i) => {
                                      return (
                                        <img
                                          key={i}
                                          src={image.media_url}
                                          alt={`Selected ${image.media_id}`}
                                          height="190"
                                          width="190"
                                          title="Use Image"
                                          style={{
                                            cursor: "pointer",
                                            margin: "5px",
                                            borderRadius: "10px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleImageSelect(
                                              index,
                                              image.media_url
                                            );
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default LogoCloudField;

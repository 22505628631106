import React, { useEffect } from "react";
import styles from "./index.module.css";
import PNGClass from "../../../../../../assets/png";
import SVGClass from "../../../../../../assets/svg";

const LogoCloudTemplate = ({ value, onChange }) => {
  useEffect(() => {
    if (value === undefined) {
      onChange("logo_cloud_1");
    }
  }, [value, onChange]);

  const saveTemplateHandler = (val) => {
    if (val !== value) {
      onChange(val);
    }
  };

  return (
    <div className={`${styles.template}`}>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_1")}
        className={`${styles.box_sizing} ${
          value === "logo_cloud_1" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud1} alt="Logo Cloud 1" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_2")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_2" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud2} alt="Logo Cloud 2" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_3")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_3" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud3} alt="Logo Cloud 3" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_4")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_4" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud4} alt="Logo Cloud 4" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_5")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_5" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud5} alt="Logo Cloud 5" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_6")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_6" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud6} alt="Logo Cloud 6" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_7")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_7" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud7} alt="Logo Cloud 7" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_cloud_8")}
        className={`${styles.box_sizing}  ${
          value === "logo_cloud_8" && styles.active
        }`}
      >
        <img src={PNGClass.logoCloud8} alt="Logo Cloud 8" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
    </div>
  );
};

export default LogoCloudTemplate;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templates_template__qbUse {
    padding: 10px 0 !important;
}

.templates_box_sizing__CkXBQ {
    border: 1px solid var(--bdr-color);
    border-radius: 6px;
    box-sizing: border-box !important;
    margin-bottom: 20px;
    /* height: 100px; */
    padding: 0;
}

.templates_box_sizing__CkXBQ img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.templates_box_sizing__CkXBQ:hover {
    cursor: pointer;
}

.templates_box_sizing__CkXBQ .templates_tick__GTYAZ {
    display: none;
}

.templates_box_sizing__CkXBQ.templates_active__dbjYL {
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    position: relative;
}

.templates_box_sizing__CkXBQ.templates_active__dbjYL .templates_tick__GTYAZ {
    display: block;
    position: absolute;
    background-color: var(--primary-color);
    color: white;
    top: 0;
    right: 0;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/testimonials/widgets/templates/index.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,iCAAiC;IACjC,mBAAmB;IACnB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,sCAAsC;IACtC,YAAY;IACZ,MAAM;IACN,QAAQ;IACR,4BAA4B;IAC5B,8BAA8B;AAClC","sourcesContent":[".template {\n    padding: 10px 0 !important;\n}\n\n.box_sizing {\n    border: 1px solid var(--bdr-color);\n    border-radius: 6px;\n    box-sizing: border-box !important;\n    margin-bottom: 20px;\n    /* height: 100px; */\n    padding: 0;\n}\n\n.box_sizing img {\n    width: 100%;\n    height: 100%;\n    border-radius: 6px;\n}\n\n.box_sizing:hover {\n    cursor: pointer;\n}\n\n.box_sizing .tick {\n    display: none;\n}\n\n.box_sizing.active {\n    border: 1px solid var(--primary-color);\n    border-radius: 6px;\n    position: relative;\n}\n\n.box_sizing.active .tick {\n    display: block;\n    position: absolute;\n    background-color: var(--primary-color);\n    color: white;\n    top: 0;\n    right: 0;\n    border-top-right-radius: 6px;\n    border-bottom-left-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": `templates_template__qbUse`,
	"box_sizing": `templates_box_sizing__CkXBQ`,
	"tick": `templates_tick__GTYAZ`,
	"active": `templates_active__dbjYL`
};
export default ___CSS_LOADER_EXPORT___;

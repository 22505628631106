import { useSelector } from "react-redux";
import SVGClass from "../../../../../../assets/svg";
import styles from "./index.module.css";
import { useCallback, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const initialViewState = {
  longitude: -122.48,
  latitude: 37.78,
  zoom: 15.5,
  bearing: 0,
  pitch: 0,
};

const ContactUsRender = ({ style, templates }) => {
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8,
    width: "100%",
    height: "50vh",
  });
  const settings = useSelector((state) => state.settings);

  const renderMap = () => (
    <>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        style={{ height: "500px", width: "100%" }}
        dragging={false}
        touchZoom={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        zoomControl={false} // Optionally disable zoom control buttons
        keyboard={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[51.505, -0.09]}>
          {/* <Popup>
        A pretty popup. <br /> Easily customizable.
      </Popup> */}
        </Marker>
      </MapContainer>
    </>
  );

  return (
    <div>
      <div className="apply_max_width_widget">
        {templates === "contact_1" ? (
          <></>
        ) : (
          <div className="d_flex justify_content_between align_items_center margin_bottom_10px">
            <div className="width_100">
              <h1
                className={templates === "contact_3" ? "text_align_center" : ""}
              >
                Let's stay connected
              </h1>
              <p
                className={templates === "contact_3" ? "text_align_center" : ""}
              >
                It is never been easier to get in touch with Boostify360. Call
                us, use our live chat widget or wmail and we'll get back to you
                as soon as possible!
              </p>
            </div>
            {templates === "contact_2" || templates === "contact_4" ? (
              <div className="width_100 d_flex justify_content_end">
                <button
                  style={{
                    backgroundColor: `${settings?.globalSettings?.themeColor}`,
                  }}
                  className={styles.contact_button}
                >
                  About us
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        {templates === "contact_2" ? (
          <div className="d_flex justify_content_between align_items_center">
            <div className="width_100 d_flex justify_content_between">
              <div className="d_flex flex_column justify_content_between">
                <div className="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style={{
                      backgroundColor: `${settings?.globalSettings?.themeColor}`,
                    }}
                    className={styles.contact_svg}
                  >
                    <SVGClass.EnvelopOpen />
                  </div>
                  <h2>Email</h2>
                  <p
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    {style.content.email}
                  </p>
                </div>
                <div className="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style={{
                      backgroundColor: `${settings?.globalSettings?.themeColor}`,
                    }}
                    className={styles.contact_svg}
                  >
                    <SVGClass.MapPinAlt />
                  </div>
                  <h2>Address</h2>
                  <p
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    {style.content.address}
                  </p>
                </div>
              </div>
              <div className="d_flex flex_column justify_content_between">
                <div className="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style={{
                      backgroundColor: `${settings?.globalSettings?.themeColor}`,
                    }}
                    className={styles.contact_svg}
                  >
                    <SVGClass.EnvelopOpen />
                  </div>
                  <h2>Phone</h2>
                  <p
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    {style.content.phone}
                  </p>
                </div>
                <div className="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style={{
                      backgroundColor: `${settings?.globalSettings?.themeColor}`,
                    }}
                    className={styles.contact_svg}
                  >
                    <SVGClass.EnvelopOpen />
                  </div>
                  <h2>Social</h2>
                  <p
                    className="d_flex justify_content_between"
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    <SVGClass.Facebook /> <SVGClass.Instagram />{" "}
                    <SVGClass.LinkedIn />
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`width_100 d_flex flex_column ${styles.subsribe_email}`}
              style={{
                marginLeft: "40px",
              }}
            >
              <div className="field_heading margin_bottom_10px">Email</div>
              <div>
                <input />
              </div>
              <div className="field_heading margin_vertical_10px">Message</div>
              <div>
                <textarea />
              </div>
              <button
                className="margin_top_10px"
                style={{
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                Send
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        {templates === "contact_1" || templates === "contact_3" ? (
          <div className="d_flex justify_content_evenly align_items_center">
            <div
              className={`d_flex flex_column flex_wrap justify_content_center align_items_center ${styles.contact_item}`}
            >
              <div
                style={{
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
                className={styles.contact_svg}
              >
                <SVGClass.EnvelopOpen />
              </div>
              <h2>Email</h2>
              <p
                style={{
                  color: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style.content.email}
              </p>
            </div>
            <div
              className={`d_flex flex_column flex_wrap justify_content_center align_items_center ${styles.contact_item}`}
            >
              <div
                style={{
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
                className={styles.contact_svg}
              >
                <SVGClass.Phone />
              </div>
              <h2>Phone</h2>
              <p
                style={{
                  color: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style.content.phone}
              </p>
            </div>
            <div
              className={`d_flex flex_column flex_wrap justify_content_center align_items_center ${styles.contact_item}`}
            >
              <div
                style={{
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
                className={styles.contact_svg}
              >
                <SVGClass.MapPinAlt />
              </div>
              <h2>Office</h2>
              <p
                style={{
                  color: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style.content.address}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        {templates === "contact_3" ? <div className="margin_top_10px">{renderMap()}</div> : <></>}
        {templates === "contact_4" ? (
          <div className="d_flex align_items_center">
            <div className="width_100">
              <div className="d_flex align_items_center jusitfy_content_evenly">
                <div
                  style={{
                    backgroundColor: `${settings?.globalSettings?.themeColor}`,
                  }}
                  className={styles.contact_svg}
                >
                  <SVGClass.EnvelopOpen />
                </div>
                <div className="margin_left_10px">
                  <h2>Email</h2>
                  <p
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    {style.content.email}
                  </p>
                </div>
              </div>
              <div className="d_flex align_items_center jusitfy_content_evenly">
                <div
                  style={{
                    backgroundColor: `${settings?.globalSettings?.themeColor}`,
                  }}
                  className={styles.contact_svg}
                >
                  <SVGClass.Phone />
                </div>
                <div className="margin_left_10px">
                  <h2>Phone</h2>
                  <p
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    {style.content.phone}
                  </p>
                </div>
              </div>
              <div className="d_flex align_items_center jusitfy_content_evenly">
                <div
                  style={{
                    backgroundColor: `${settings?.globalSettings?.themeColor}`,
                  }}
                  className={styles.contact_svg}
                >
                  <SVGClass.EnvelopOpen />
                </div>
                <div className="margin_left_10px">
                  <h2>Socials</h2>
                  <p
                    className="d_flex justify_content_between"
                    style={{
                      color: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    <SVGClass.Facebook /> <SVGClass.Instagram />{" "}
                    <SVGClass.LinkedIn />
                  </p>
                </div>
              </div>
            </div>
            <div className="width_100">{renderMap()}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ContactUsRender;

export const TestimonialCSS = `
    .testimonial_image_1 {
    border-radius: 50%;
}

.testimonial_dot {
    cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active, .dot:hover {
    background-color: #717171;
  }

  .testimonial_image_2{
    border-radius: 20px;
  }


  .prev_and_next_btn {
    margin: 10px 40px;
    padding: 10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .testimonial_4_items {
    min-width: 300px; 
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    padding: 15px;
    background-color: white;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


.mySlides {display: none}
`;

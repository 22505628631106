class PNGClass {
  static basicHeader = require("./basic_header.png");
  static logoWithNavLinks = require("./logo_with_navlinks_header.png");
  static logoSearch = require("./logo_search_header.png");
  static SocialHeader = require("./social_header.png");
  static FloatingHeader = require("./floating_header.png");
  static MenuButtonHeader = require("./menu_button_header.png");

  static basicFooter = require("./basic_footer.jpg");
  static appFooter = require("./app_footer.jpg");
  static detailedFooter = require("./detailed_footer.jpg");
  static newsLetterFooter = require("./newsletter_footer.jpg");
  static simpleFooter = require("./simple_footer.jpg");

  static hero1 = require("./hero1.jpg");
  static hero2 = require("./hero2.jpg");
  static hero3 = require("./hero3.jpg");
  static hero4 = require("./hero4.jpg");
  static hero5 = require("./hero5.jpg");
  static hero6 = require("./hero1.jpg");
  static hero7 = require("./hero2.jpg");

  static features1 = require("./features_1.png");
  static features2 = require("./features_2.png");
  static features3 = require("./features_3.png");
  static features4 = require("./features_4.png");
  static features5 = require("./features_5.png");
  static features6 = require("./features_6.png");
  static features7 = require("./features_7.png");
  static features8 = require("./features_8.png");

  static faqs1 = require("./faqs_1.png");
  static faqs2 = require("./faqs_2.png");
  static faqs3 = require("./faqs_3.png");
  static faqs4 = require("./faqs_4.png");

  static contact1 = require("./contact_1.png");
  static contact2 = require("./contact_2.png");
  static contact3 = require("./contact_3.png");
  static contact4 = require("./contact_4.png");

  static team1 = require("./team_1.png");
  static team2 = require("./team_2.png");
  static team3 = require("./team_3.png");
  static team4 = require("./team_4.png");
  static team5 = require("./team_5.png");

  static logoCloud1 = require("./logo_cloud_1.png");
  static logoCloud2 = require("./logo_cloud_2.png");
  static logoCloud3 = require("./logo_cloud_3.png");
  static logoCloud4 = require("./logo_cloud_4.png");
  static logoCloud5 = require("./logo_cloud_5.png");
  static logoCloud6 = require("./logo_cloud_6.png");
  static logoCloud7 = require("./logo_cloud_7.png");
  static logoCloud8 = require("./logo_cloud_8.png");

  static noImage = require("./no_image.png");

  static boostify360 = require("./boostify360Logo.png");
  static desktop = require("./desktop.png");
  static edit = require("./edit.png");
  static globalThemeInactive = require("./global_theme_inactive.png");
  static globalTheme = require("./global_theme.png");
  static mobileInactive = require("./mobile_inactive.png");
  static mobile = require("./mobile.png");
  static picturesInactive = require("./pictures_inactive.png");
  static pictures = require("./pictures.png");
  static preview = require("./preview.png");
  static blog = require("./blog.png");
  static save = require("./save.png");
  static search = require("./search.png");
  static select = require("./select.png");
  static settingsInactive = require("./settings_inactive.png");
  static settings = require("./settings.png");
  static tabletInactive = require("./tablet_inactive.png");
  static tablet = require("./tablet.png");
  static widgetsInactive = require("./widgets_inactive.png");
  static widgets = require("./widgets.png");
  static zoomIn = require("./zoom_in.png");
  static zoomOut = require("./zoom_out.png");
}

export default PNGClass;

import ArrowDownSVG from "./arrow_down";
import ArrowUpSVG from "./arrow_up";
// import BoostifyLogoSVG from "./boostify360";
import CheckSVG from "./check";
import DesktopSVG from "./desktop";
import EditSVG from "./edit";
import GlobalThemeInactiveSVG from "./global_theme_inactive";
import GlobalThemeSVG from "./global_theme";
import MobileSVG from "./mobile";
import PalleteSVG from "./palette";
import PicturesSVG from "./pictures";
import PicturesInactiveSVG from "./pictures_inactive";
import PlusSVG from "./plus";
import PreviewSVG from "./preview";
import SaveSVG from "./save";
import SearchSVG from "./search";
import SettingsSVG from "./settings";
import SettingsInactiveSVG from "./settings_inactive";
import TabletSVG from "./tablet";
import TickSVG from "./tick";
import WidgetsSVG from "./widgets";
import WidgetsInactiveSVG from "./widgets_inactive";
import ZoomInSVG from "./zoom_in";
import ZoomOutSVG from "./zoom_out";
import SelectSVG from "./select";
import TrashSVG from "./trash";
import EyeCloseSVG from "./eye_close";
import EyeOpenSVG from "./eye_open";
import ExpandSVG from "./expand";
import FloppySVG from "./floppy_disk";
import FileSVG from "./file";
import FileImageSVG from "./file_image";
import PaintBucketSVG from "./paint_bucket";
import PagesSVG from "./pages";
import EthernetSVG from "./ethernet";
import HeaderSVG from "./header";
import FooterSVG from "./footer";
import ThreeDotsVerticalSVG from "./three_dots_vertical";
import RedoSVG from "./redo";
import UndoSVG from "./undo";
import DesktopFillSVG from "./desktop_fill";
import MobileFillSVG from "./mobile_fill";
import TabletFillSVG from "./tablet_fill";
import HomeSVG from "./home";
import Gallery30SVG from "./gallery_30";
import DropMediaSVG from "./drop_media";
import AlertSVG from "./alert";
import BlogsSVG from "./blogs";
import TickBigSVG from "./tick_big_size";
import CompressSVG from "./compress";
import FacebookSVG from "./facebook";
import InstagramSVG from "./instagram";
import LinkedInSVG from "./linkedin";
import MenuSVG from "./menu";
import LogoHereSVG from "./logo_here";
import EnvelopOpenSVG from "./envelop_open";
import MapPinAltSVG from "./map_pin_alt";
import PhoneSVG from "./phone";
import ArrowLeftSVG from "./arrow_left";
import ArrowRightSVG from "./arrow_right";

class SVGClass {
  static Alert = AlertSVG;
  static ArrowDown = ArrowDownSVG;
  static ArrowUp = ArrowUpSVG;
  static ArrowLeft = ArrowLeftSVG;
  static ArrowRight = ArrowRightSVG
  // static boostify360 = BoostifyLogoSVG;
  static Blogs = BlogsSVG;
  static Check = CheckSVG;
  static Compress = CompressSVG;
  static Desktop = DesktopSVG;
  static DesktopFill = DesktopFillSVG;
  static DropMedia = DropMediaSVG;
  static Edit = EditSVG;
  static EnvelopOpen = EnvelopOpenSVG;
  static Ethernet = EthernetSVG;
  static EyeOpen = EyeOpenSVG;
  static EyeClose = EyeCloseSVG;
  static Expand = ExpandSVG;
  static Facebook = FacebookSVG;
  static FloppyDisk = FloppySVG;
  static File = FileSVG;
  static FileImage = FileImageSVG;
  static Footer = FooterSVG;
  static Gallery30 = Gallery30SVG;
  static GlobalThemeInactive = GlobalThemeInactiveSVG;
  static GlobalTheme = GlobalThemeSVG;
  static Header = HeaderSVG;
  static Home = HomeSVG;
  static Instagram = InstagramSVG;
  static LinkedIn = LinkedInSVG;
  static LogoHere = LogoHereSVG;
  static MapPinAlt = MapPinAltSVG;
  static Menu = MenuSVG;
  static Mobile = MobileSVG;
  static MobileFill = MobileFillSVG;
  static Pages = PagesSVG;
  static Pallete = PalleteSVG;
  static Phone = PhoneSVG;
  static Pictures = PicturesSVG;
  static PicturesInactive = PicturesInactiveSVG;
  static Plus = PlusSVG;
  static Preview = PreviewSVG;
  static PaintBucket = PaintBucketSVG;
  static Redo = RedoSVG;
  static Undo = UndoSVG;
  static Save = SaveSVG;
  static Search = SearchSVG;
  static Select = SelectSVG;
  static Settings = SettingsSVG;
  static SettingsInactive = SettingsInactiveSVG;
  static Tablet = TabletSVG;
  static TabletFill = TabletFillSVG;
  static Tick = TickSVG;
  static TickBig = TickBigSVG;
  static Trash = TrashSVG;
  static ThreeDotsVertical = ThreeDotsVerticalSVG;
  static Widgets = WidgetsSVG;
  static WidgetsInactive = WidgetsInactiveSVG;
  static ZoomIn = ZoomInSVG;
  static ZoomOut = ZoomOutSVG;
}

export default SVGClass;

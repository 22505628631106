export const getContactUsHTML = (data, settings) => {
  const envelopeSVG = `
  <svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M21 8v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8m18 0-8.029-4.46a2 2 0 0 0-1.942 0L3 8m18 0-9 6.5L3 8"
    />
  </svg>
  `;

  const instagramSVG = `<svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
      clip-rule="evenodd"
    />
  </svg>`;

  const linkedInSVG = `<svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
      clip-rule="evenodd"
    />
    <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
  </svg>`;

  const phoneSVG = `<svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
    />
  </svg>`;

  const mapAltPinSVG = `<svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
    />
  </svg>`;

  const facebookSVG = `<svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
      clip-rule="evenodd"
    />
  </svg>`;

  return `
      <div>
      <div class="apply_max_width_widget">
        ${
          data.templates === "contact_1"
            ? ""
            : `<div class="d_flex justify_content_between align_items_center margin_bottom_10px">
            <div class="width_100">
              <h1
                class="${
                  data.templates === "contact_3" ? "text_align_center" : ""
                }"
              >
                Let's stay connected
              </h1>
              <p
                class="${
                  data.templates === "contact_3" ? "text_align_center" : ""
                }"
              >
                It is never been easier to get in touch with Boostify360. Call
                us, use our live chat widget or wmail and we'll get back to you
                as soon as possible!
              </p>
            </div>
            ${
              data.templates === "contact_2" || data.templates === "contact_4"
                ? `<div class="width_100 d_flex justify_content_end">
                <button style="background-color:${settings?.globalSettings?.themeColor};"
                  class="contact_button"
                >
                  About us
                </button>
              </div>`
                : ""
            }
          </div>`
        }
        ${
          data.templates === "contact_2" ? (
            `<div class="d_flex justify_content_between align_items_center">
            <div class="width_100 d_flex justify_content_between">
              <div class="d_flex flex_column justify_content_between">
                <div class="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style="background-color:${settings?.globalSettings?.themeColor};"
                    class="contact_svg"
                  >
                    ${envelopeSVG}
                  </div>
                  <h2>Email</h2>
                  <p
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${data.style.content.email}
                  </p>
                </div>
                <div class="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style="background-color:${settings?.globalSettings?.themeColor};"
                    class="contact_svg"
                  >
                    ${mapAltPinSVG}
                  </div>
                  <h2>Address</h2>
                  <p
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${data.style.content.address}
                  </p>
                </div>
              </div>
              <div class="d_flex flex_column justify_content_between">
                <div class="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style="background-color:${settings?.globalSettings?.themeColor};"
                    class="contact_svg"
                  >
                    ${envelopeSVG}
                  </div>
                  <h2>Phone</h2>
                  <p
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${data.style.content.phone}
                  </p>
                </div>
                <div class="d_flex flex_column align_items_center jusitfy_content_center">
                  <div
                    style="background-color:${settings?.globalSettings?.themeColor};"
                    class="contact_svg"
                  >
                    ${envelopeSVG}
                  </div>
                  <h2>Social</h2>
                  <p
                    class="d_flex justify_content_between"
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${facebookSVG} ${instagramSVG}
                    ${linkedInSVG}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="width_100 d_flex flex_column subsribe_email"
              style="margin-left:40px;"
            >
              <div class="field_heading margin_bottom_10px">Email</div>
              <div>
                <input />
              </div>
              <div class="field_heading margin_vertical_10px">Message</div>
              <div>
                <textarea />
              </div>
              <button
                class="margin_top_10px"
                style="background-color:${settings?.globalSettings?.themeColor};"
              >
                Send
              </button>
            </div>
          </div>`
          ) : (
            ""
          )
        }
        ${
          data.templates === "contact_1" || data.templates === "contact_3"
            ? `<div class="d_flex justify_content_evenly align_items_center">
            <div
              class="d_flex flex_column flex_wrap justify_content_center align_items_center contact_item"
            >
              <div
                style="background-color:${settings?.globalSettings?.themeColor};"
                class="contact_svg"
              >
                ${envelopeSVG}
              </div>
              <h2>Email</h2>
              <p
                style="color:${settings?.globalSettings?.themeColor};"
              >
                ${data.style.content.email}
              </p>
            </div>
            <div
              class="d_flex flex_column flex_wrap justify_content_center align_items_center contact_item"
            >
              <div
                style="background-color:${settings?.globalSettings?.themeColor};"
                class="contact_svg"
              >
                ${phoneSVG}
              </div>
              <h2>Phone</h2>
              <p
                style="color:${settings?.globalSettings?.themeColor};"
              >
                ${data.style.content.phone}
              </p>
            </div>
            <div
              class="d_flex flex_column flex_wrap justify_content_center align_items_center contact_item"
            >
              <div
                style="background-color:${settings?.globalSettings?.themeColor};"
                class="contact_svg"
              >
                ${mapAltPinSVG}
              </div>
              <h2>Office</h2>
              <p
                style="color:${settings?.globalSettings?.themeColor};"
              >
                ${data.style.content.address}
              </p>
            </div>
          </div>`
            : ""
        }
        ${
          data.templates === "contact_3"
            ? `<div class="margin_top_10px"><div id="map"></div></div>`
            : ""
        }
        ${
          data.templates === "contact_4"
            ? `<div class="d_flex align_items_center">
            <div class="width_100">
              <div class="d_flex align_items_center jusitfy_content_evenly">
                <div
                  style="background-color:${settings?.globalSettings?.themeColor};"
                  class="contact_svg"
                >
                  ${envelopeSVG}
                </div>
                <div class="margin_left_10px">
                  <h2>Email</h2>
                  <p
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${data.style.content.email}
                  </p>
                </div>
              </div>
              <div class="d_flex align_items_center jusitfy_content_evenly">
                <div
                  style="background-color:${settings?.globalSettings?.themeColor};"
                  class="contact_svg"
                >
                  ${phoneSVG}
                </div>
                <div class="margin_left_10px">
                  <h2>Phone</h2>
                  <p
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${data.style.content.phone}
                  </p>
                </div>
              </div>
              <div class="d_flex align_items_center jusitfy_content_evenly">
                <div
                  style="background-color:${settings?.globalSettings?.themeColor};"
                  class="contact_svg"
                >
                  ${envelopeSVG}
                </div>
                <div class="margin_left_10px">
                  <h2>Socials</h2>
                  <p
                    class="d_flex justify_content_between"
                    style="color:${settings?.globalSettings?.themeColor};"
                  >
                    ${facebookSVG} ${instagramSVG}
                    ${linkedInSVG}
                  </p>
                </div>
              </div>
            </div>
            <div class="width_100"><div id="map"></div></div>
          </div>`
            : ""
        }
      </div>
    </div>
      `;
};

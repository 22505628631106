import React, { useEffect } from "react";
import styles from "./index.module.css";
import PNGClass from "../../../../../../assets/png";
import SVGClass from "../../../../../../assets/svg";

const ContactUsTemplate = ({ onChange, value }) => {
  useEffect(() => {
    if (value === undefined) {
      onChange("contact_1");
    }
  }, [value, onChange]);

  const saveTemplateHandler = (val) => {
    if (val !== value) {
      onChange(val);
    }
  };

  return (
    <div className={`${styles.template}`}>
      <div
        onClick={() => saveTemplateHandler("contact_1")}
        className={`${styles.box_sizing} ${
          value === "contact_1" && styles.active
        }`}
      >
        <img src={PNGClass.contact1} alt="CONTACT 1" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("contact_2")}
        className={`${styles.box_sizing}  ${
          value === "contact_2" && styles.active
        }`}
      >
        <img src={PNGClass.contact2} alt="CONTACT 2" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("contact_3")}
        className={`${styles.box_sizing}  ${
          value === "contact_3" && styles.active
        }`}
      >
        <img src={PNGClass.contact3} alt="CONTACT 3" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("contact_4")}
        className={`${styles.box_sizing}  ${
          value === "contact_4" && styles.active
        }`}
      >
        <img src={PNGClass.contact4} alt="CONTACT 4" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
    </div>
  );
};

export default ContactUsTemplate;

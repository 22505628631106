import { useSelector } from "react-redux";
import SVGClass from "../../../../../../assets/svg";
import styles from "../../index.module.css";

const OurTeamRender = ({ style, templates }) => {
  const settings = useSelector((state) => state.settings);

  const renderRow = (startIndex, isLargeFirst) => {
    const largeImage = style.details[startIndex];
    const smallImages = style.details?.slice(startIndex + 1, startIndex + 3);
    return (
      <div className={styles.our_team_grid_container}>
        {isLargeFirst && largeImage && (
          <div className={styles.our_team_large_image}>
            <img
              className={styles.our_team_images}
              src={largeImage.image}
              alt={largeImage.name}
            />
            <div className={styles.our_team_overlay}>
              <h3>{largeImage.name}</h3>
              <p
                style={{
                  color: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {" "}
                {largeImage.designation}
              </p>
            </div>
          </div>
        )}

        <div className={styles.our_team_small_images_column}>
          {smallImages.map((image, index) => (
            <div className={styles.our_team_small_image} key={index}>
              <img
                className={styles.our_team_images}
                src={image.image}
                alt={image.name}
              />
              <div className={styles.our_team_overlay}>
                <h3>{image.name}</h3>
                <p
                  style={{
                    color: `${settings?.globalSettings?.themeColor}`,
                  }}
                >
                  {image.designation}
                </p>
              </div>
            </div>
          ))}
        </div>

        {!isLargeFirst && largeImage && (
          <div className={styles.our_team_large_image}>
            <img
              className={styles.our_team_images}
              src={largeImage.image}
              alt={largeImage.name}
            />
            <div className={styles.our_team_overlay}>
              <h3>{largeImage.name}</h3>
              <p
                style={{
                  color: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {largeImage.designation}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="apply_max_width_widget">
        <div
          className={`${
            templates === "our_team_4" || templates === "our_team_5"
              ? "d_flex justify_content_between"
              : ""
          }`}
        >
          <div className="width_100">
            <h1
              className={
                templates === "our_team_2" || templates === "our_team_3"
                  ? "text_align_center"
                  : ""
              }
            >
              {style.content?.title}
            </h1>
            <p
              className={
                templates === "our_team_2" || templates === "our_team_3"
                  ? "text_align_center"
                  : ""
              }
            >
              {style.content?.description}
            </p>
          </div>
          {templates === "our_team_3" ||
          templates === "our_team_4" ||
          templates === "our_team_5" ? (
            <div className="width_100 d_flex align_items_center justify_content_center">
              <button
                className={styles.our_team_first_button}
                style={{
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style.first_button.title}
              </button>
              <button
                className={styles.our_team_second_button}
                style={{
                  color: `${settings?.globalSettings?.themeColor}`,
                  border: `1px solid ${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style.second_button.title}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {templates === "our_team_5" ? (
          <div>
            {style.details?.map((_, index) => {
              if (index % 3 === 0) {
                return renderRow(index, index % 6 === 0); // Alternating pattern: large image first for every second group
              }
              return null;
            })}
          </div>
        ) : (
          <div className={`d_flex flex_wrap width_100 `}>
            {style.details?.map((item, index) => (
              <div
                className={`margin_10px ${styles.our_team_member_item}`}
                key={index}
              >
                <div
                  className={`${
                    templates === "our_team_2" || templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  } ${
                    templates === "our_team_1" ||
                    templates === "our_team_2" ||
                    templates === "our_team_3"
                      ? styles.our_team_member_item_123
                      : ""
                  } ${templates === "our_team_4" ? styles.our_team_4 : ""}`}
                >
                  <img src={item.image} alt={`ourteam_${index}`} />
                </div>
                <p
                  className={
                    templates === "our_team_2" || templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  }
                >
                  <b>{item.name}</b>
                </p>
                <p
                  className={`
                  ${
                    templates === "our_team_2" || templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  }
                    `}
                  style={{
                    color: `${settings?.globalSettings?.themeColor}`,
                  }}
                >
                  {item.designation}
                </p>
                <p
                  className={
                    templates === "our_team_2" ||
                    templates === "our_team_4" ||
                    templates === "our_team_5"
                      ? "d_none"
                      : templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  }
                >
                  {item.description}
                </p>
                <div
                  className={`d_flex ${
                    templates === "our_team_2" || templates === "our_team_3"
                      ? "justify_content_center"
                      : ""
                  }`}
                >
                  <SVGClass.Facebook />
                  <SVGClass.Instagram />
                  <SVGClass.LinkedIn />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OurTeamRender;

import { useCallback, useEffect, useRef, useState } from "react";
import SVGClass from "../../../../../../assets/svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getFileLinksWithSiteId, uploadFile } from "../../../../../../apis";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFileLinksWithSiteIdThunk } from "../../../../../../redux/thunks";
import { useSelector } from "react-redux";
import Modal from "../../../../../../components/modal";

const TestimonialMember = ({ onChange, value }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const [selectedInput, setSelectedInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { siteId } = useParams();
  const media = useSelector((state) => state.media.media);
  const [imageModal, setImageModal] = useState(false);
  const stateDispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("upload");
  const [link, setLink] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    return () => {
      setSelectedInput(null);
    };
  }, []);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const onChangeLinkHandler = (e) => {
    setLink(e.target.value);
    onChange(e.target.value);
  };

  const handleFileChange = async (index, event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      const uploadImageUrl = await uploadFile(file, siteId);
      // const uploadImageUrl = await uploadFileThunk(file, siteId);
      if (uploadImageUrl) {
        const newInputs = value.map((input, i) =>
          i === index
            ? {
                ...input,
                image: `http://boostify360.b-cdn.net/${uploadImageUrl}`,
              }
            : input
        );
        onChange(newInputs);
        getGalleryMedia();
      }
    }
    setIsLoading(false);
  };

  const handleImageSelect = async (index, imageUrl) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, image: imageUrl } : input
    );
    onChange(newInputs);
    setImageModal(!imageModal);
  };

  const getGalleryMedia = useCallback(async () => {
    try {
      await stateDispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
    } catch (e) {}
  }, [siteId, stateDispatch]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const getGalleryItems = async () => {
    setIsLoading(true);
    await getFileLinksWithSiteId(siteId)
      .then((res) => {
        if (res.success === true) {
          setImageModal(true);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* ----------------- SETTING ONE INPUT AT LEAST IF NOT FOUND ---------------- */
  useEffect(() => {
    if (value && (value === undefined || value?.length === 0)) {
      const newInput = {
        id: Date.now().toLocaleString().split(",").join(""),
        name: "Member",
        designation: "CEO",
        testimonial: "Testimonial",
        image: "http://boostify360.b-cdn.net/NEk7K4SZq699921355kv1728317145/1728320801149.png",
      };
      onChange([...value, newInput]);
    }
  }, [onChange, value]);

  /* ---------------------------- ADD INPUT HANDLER --------------------------- */
  const addInputHandler = () => {
    let items;
    const newInput = {
      id: Date.now().toLocaleString().split(",").join(""),
      name: "Member",
      designation: "CEO",
      testimonial: "Testimonial",
      image: "http://boostify360.b-cdn.net/NEk7K4SZq699921355kv1728317145/1728320801149.png"
    };
    items = [...value, newInput];

    onChange(items);
  };

  /* -------------------------- LABEL CHANGE HANDLER -------------------------- */
  const nameChangeHandler = (index, name) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, name } : input
    );
    onChange(newInputs);
  };

  /* -------------------------- LABEL CHANGE HANDLER -------------------------- */
  const designationChangeHandler = (index, designation) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, designation } : input
    );
    onChange(newInputs);
  };

  /* -------------------------- LABEL CHANGE HANDLER -------------------------- */
  const testimonialChangeHandler = (index, testimonial) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, testimonial } : input
    );
    onChange(newInputs);
  };

  /* ------------------------- SELECTED INPUT HANDLER ------------------------- */
  const selectedInputHandler = (item) => {
    if (selectedInput?.id === item.id) {
      setSelectedInput(null);
    } else {
      setSelectedInput(item);
    }
  };

  /* --------------------------- ON DRAG END HANDLER -------------------------- */
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedInputs = Array.from(value.inputs);
    const [removed] = reorderedInputs.splice(result.source.index, 1);
    reorderedInputs.splice(result.destination.index, 0, removed);

    onChange({ ...value, inputs: reorderedInputs });
  };

  return (
    <div>
      <span className="field_heading">Member Details</span>
      <div>
        <button
          className="d_flex justify_content_center width_100 primary_button margin_vertical_10px"
          onClick={addInputHandler}
        >
          Add Member
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {value &&
                value.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="cursor_pointer"
                      >
                        <div
                          className="gray_border normal_rounded padding_10px margin_vertical_10px d_flex justify_content_between align_items_center"
                          onClick={() => selectedInputHandler(item)}
                        >
                          {item.name}
                          <div>
                            <SVGClass.Trash />
                          </div>
                        </div>
                        {selectedInput && selectedInput?.id === item.id && (
                          <div className="gray_border normal_rounded padding_10px">
                            <div className="field_heading margin_bottom_10px">
                              Name
                            </div>
                            <div className="field_label_field_input__u5-69">
                              <input
                                className="_Input-input_1qi5b_46"
                                autoComplete="off"
                                type="text"
                                value={item.name || ""}
                                onChange={(e) =>
                                  nameChangeHandler(index, e.target.value)
                                }
                              />
                            </div>
                            <div className="field_heading margin_vertical_10px">
                              Designation
                            </div>
                            <div className="field_label_field_input__u5-69">
                              <input
                                className="_Input-input_1qi5b_46"
                                autoComplete="off"
                                type="text"
                                value={item.designation || ""}
                                onChange={(e) =>
                                  designationChangeHandler(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="field_heading margin_vertical_10px">
                              Testimonial
                            </div>
                            <div className="field_label_field_input__u5-69">
                              <textarea
                                className="_Input-input_1qi5b_46"
                                autoComplete="off"
                                type="text"
                                value={item.testimonial || ""}
                                onChange={(e) =>
                                  testimonialChangeHandler(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="field_heading">Image</div>
                            <div className="d_block">
                              <div className="field_label margin_vertical_10px">
                                Upload Image
                              </div>
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="d_none"
                                onChange={(e)=>handleFileChange(index, e)}
                                accept="image/*"
                              />
                              <div className="d_flex justify_content_center margin_bottom_10px">
                                <img
                                  className="normal_rounded"
                                  src={
                                    item.image
                                      ? item.image
                                      : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
                                  }
                                  alt="select_Image"
                                  height={80}
                                />
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="upload"
                                    checked={selectedOption === "upload"}
                                    onChange={handleOptionChange}
                                  />
                                  Upload Image
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    value="link"
                                    checked={selectedOption === "link"}
                                    onChange={handleOptionChange}
                                  />
                                  Use Image Link
                                </label>
                              </div>
                              {selectedOption === "upload" ? (
                                <div className="d_flex align_items_center">
                                  {media.length !== 0 && (
                                    <button
                                      onClick={getGalleryItems}
                                      className="secondary_button width_100 d_flex justify_content_center padding_10px margin_right_10px"
                                    >
                                      Choose Image
                                    </button>
                                  )}
                                  <button
                                    onClick={handleClick}
                                    className="secondary_button width_100 d_flex justify_content_center padding_10px"
                                  >
                                    Upload Image
                                  </button>
                                </div>
                              ) : (
                                <div className="field_label_field_input__u5-69">
                                  <input
                                    className="_Input-input_1qi5b_46"
                                    type="text"
                                    value={link}
                                    placeholder="Paste The Link"
                                    onChange={onChangeLinkHandler}
                                  />
                                </div>
                              )}
                              <Modal
                                showModal={imageModal}
                                setShowModal={setImageModal}
                              >
                                <div className="popup_section">
                                  <h4 className="popup_title">Galary Data</h4>
                                  <p
                                    className={`popup_subtitle text_align_center`}
                                  >
                                    Please select Image
                                  </p>
                                  <div
                                    style={{
                                      maxHeight: "50vh",
                                    }}
                                  >
                                    {media.map((image, i) => {
                                      return (
                                        <img
                                          key={i}
                                          src={image.media_url}
                                          alt={`Selected ${image.media_id}`}
                                          height="190"
                                          width="190"
                                          title="Use Image"
                                          style={{
                                            cursor: "pointer",
                                            margin: "5px",
                                            borderRadius: "10px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleImageSelect(
                                              index,
                                              image.media_url
                                            );
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default TestimonialMember;

// import { ImageInput } from "../../../field_objects/image_input";
import WebsiteTarget from "../../../field_objects/website_target";
import LinkWithButton from "../../../fields/custom/link_with_button";
import FAQField from "./widgets/fields/faq";
import FAQsRender from "./widgets/render";
// import Rendering from "./rendering";
import FAQsTemplate from "./widgets/templates";

export const FAQs = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        content: {
          type: "object",
          label: "Content",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            description: {
              type: "textarea",
              label: "Description",
            },
          },
        },
        faq: {
          type: "custom",
          render: FAQField,
        },
        // image: {
        //   type: "object",
        //   label: "Image",
        //   objectFields: {
        //     url: ImageInput("", "faqs"),
        //   },
        // },
        button: {
          type: "object",
          label: "Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            description: {
              type: "textarea",
              label: "Description",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="first_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName={"first_button"} />
              ),
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: FAQsTemplate,
    },
  },
  defaultProps: {
    style: {
      content: {
        title: "Frequently Asked Questions",
        description: "This page was built with Boostify",
      },
      faq: [],
      //   logo: {
      //     image: "",
      //   },
      button: {
        title: "Visit Boostify360",
        description: "Learn more about Boostify360",
        linkWith: "#",
        openWebAddress: "current",
      },
    },
    templates: "faqs_1",
  },
  render: FAQsRender,
};

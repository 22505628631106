import LogoCloudField from "./widgets/fields/logo_cloud_field";
import LogoCloudRender from "./widgets/render";
import LogoCloudTemplate from "./widgets/templates";

export const LogoCloud = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        content: {
          type: "object",
          label: "Content",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            description: {
              type: "textarea",
              label: "Description",
            },
          },
        },
        details: {
          type: "custom",
          render: LogoCloudField,
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: LogoCloudTemplate,
    },
  },
  defaultProps: {
    style: {
      content: {
        title: "Trusted by the top companies in this industry",
        description:
          "The only SaaS business platform that combines CRM, marketing automation & commerce.",
      },
      details: [
        {
          id: Math.random(),
          image: "https://boostify360.b-cdn.net/NeH365Vbn746OX7ZCid11724453563/1727113558427.png",
          name: "Boostify360",
        },
      ],
    },
    templates: "logo_cloud_1",
  },
  render: LogoCloudRender,
};

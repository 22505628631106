// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.render_form_inputs__LRIoN input {
    border: 1px solid var(--bdr-color);
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 18px;
    box-sizing: border-box;
}

.render_form_inputs__LRIoN textarea {
    border: 1px solid var(--bdr-color);
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 18px;
    box-sizing: border-box;
}

.render_form_inputs__LRIoN ul {
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/form/widgets/render/index.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".form_inputs input {\n    border: 1px solid var(--bdr-color);\n    border-radius: 6px;\n    padding: 5px 10px;\n    font-size: 18px;\n    box-sizing: border-box;\n}\n\n.form_inputs textarea {\n    border: 1px solid var(--bdr-color);\n    border-radius: 6px;\n    padding: 5px 10px;\n    font-size: 18px;\n    box-sizing: border-box;\n}\n\n.form_inputs ul {\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_inputs": `render_form_inputs__LRIoN`
};
export default ___CSS_LOADER_EXPORT___;

export const ContactUsCSS = `
.contact_svg {
    color: white;
    padding: 16px;
    border-radius: 50%;
}

.contact_item h1, .contact_item p {
    padding: 0;
    margin: 0;
}

.contact_button {
    border: none;
    color: white;
    padding: 10px 25px;
    border-radius: 10px;
}

.subsribe_email {
    padding: 0 80px;
}

.subsribe_email input, .subsribe_email textarea {
    border: 1px solid var(--bdr-color);
    width: 100%;
}

.subsribe_email button {
    border: none;
    padding: 10px 0 !important;
    width: 100%;
    color: white;
    border-radius: 10px;

}`; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.render_item_max_width__WsbKo {
    max-width: 300px;
}

.render_item_style__rtTHd {
    margin: 4px;
    border-radius: 10px;
    background-color: rgb(236, 236, 236);
}

.render_faq_item__kANhM {
    padding: 10px;
}

.render_faqs_2__sKsTe {
    margin: 0 100px;
}

.render_additional_question__zkbCr {
    background-color: #2a3342;
    color: white;
    padding: 20px;
}

.render_additional_question__zkbCr h1 {
    padding: 0;
    margin: 0;
}

.render_additional_question__zkbCr button {
    padding: 6px 50px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: larger;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/faqs/widgets/render/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,sCAAsC;IACtC,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".item_max_width {\n    max-width: 300px;\n}\n\n.item_style {\n    margin: 4px;\n    border-radius: 10px;\n    background-color: rgb(236, 236, 236);\n}\n\n.faq_item {\n    padding: 10px;\n}\n\n.faqs_2 {\n    margin: 0 100px;\n}\n\n.additional_question {\n    background-color: #2a3342;\n    color: white;\n    padding: 20px;\n}\n\n.additional_question h1 {\n    padding: 0;\n    margin: 0;\n}\n\n.additional_question button {\n    padding: 6px 50px;\n    background-color: var(--primary-color);\n    color: white;\n    border: none;\n    border-radius: 20px;\n    font-size: larger;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item_max_width": `render_item_max_width__WsbKo`,
	"item_style": `render_item_style__rtTHd`,
	"faq_item": `render_faq_item__kANhM`,
	"faqs_2": `render_faqs_2__sKsTe`,
	"additional_question": `render_additional_question__zkbCr`
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from "react";
import styles from "./index.module.css"
import PNGClass from "../../../../../../assets/png";
import SVGClass from "../../../../../../assets/svg";

const TestimonialTemplates = ({onChange, value}) => {

    useEffect(() => {
        if (value === undefined) {
          onChange("testimonial_1");
        }
      }, [value, onChange]);
    
      const saveTemplateHandler = (val) => {
        if (val !== value) {
          onChange(val);
        }
      };
    

    return <div className={`${styles.template}`}>
    <div
      onClick={() => saveTemplateHandler("testimonial_1")}
      className={`${styles.box_sizing} ${
        value === "testimonial_1" && styles.active
      }`}
    >
      <img src={PNGClass.logoCloud1} alt="Testimonial 1" />
      <div className={styles.tick}>
        <SVGClass.Check />
      </div>
    </div>
    <div
      onClick={() => saveTemplateHandler("testimonial_2")}
      className={`${styles.box_sizing}  ${
        value === "testimonial_2" && styles.active
      }`}
    >
      <img src={PNGClass.logoCloud2} alt="Testimonial 2" />
      <div className={styles.tick}>
        <SVGClass.Check />
      </div>
    </div>
    <div
      onClick={() => saveTemplateHandler("testimonial_3")}
      className={`${styles.box_sizing}  ${
        value === "testimonial_3" && styles.active
      }`}
    >
      <img src={PNGClass.logoCloud3} alt="Testimonial 3" />
      <div className={styles.tick}>
        <SVGClass.Check />
      </div>
    </div>
    <div
      onClick={() => saveTemplateHandler("testimonial_4")}
      className={`${styles.box_sizing}  ${
        value === "testimonial_4" && styles.active
      }`}
    >
      <img src={PNGClass.logoCloud4} alt="Testimonial 4" />
      <div className={styles.tick}>
        <SVGClass.Check />
      </div>
    </div>
  </div>;
}

export default TestimonialTemplates;
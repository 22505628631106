export const getTestimonialHTMl = (data) => {
  if (data.templates === "testimonial_1") {
    return `<div class="apply_max_width_widget">
        ${data.style.members
          .map(
            (item, index) =>
              `<div
              key="${index}"
              class="mySlides fade width_100"
            >
              <h1 class="text_align_center">${item.testimonial}</h1>
              <div class="d_flex justify_content_center">
                <img
                  class="testimonial_image_1"
                  src="${item.image}"
                  alt="member"
                  height="100"
                />
              </div>
              <h4 class="text_align_center">${item.name}</h4>
              <p class="text_align_center">${item.designation}</p>
            </div>`
          )
          .join("")}
        <div class="text_align_center">
          ${data.style.members
            .map(
              (item, index) =>
                `<span
            key="${index}"
              class="testimonial_dot"
              onclick="currentSlide(${index + 1})"
            ></span>`
            )
            .join("")}
        </div>
      </div>`;
  } else if (data.templates === "testimonial_2") {
    return `<div class="apply_max_width_widget">
        ${data.style.members
          .map(
            (item, index) =>
              `<div key="${index}" class="mySlides fade">
            <div class="d_flex justify_content_between align_items_center">
              <div class="width_100">
                <img
                  class="testimonial_image_2"
                  src="${item.image}"
                  alt="member"
                  height="300"
                />
              </div>
              <div class="width_100">
                <h1>${item.testimonial}</h1>
                <h4>${item.name}</h4>
                <p>${item.designation}</p>
              </div>
              </div>
            </div>`
          )
          .join("")}
        <div class="text_align_center">
          ${data.style.members
            .map(
              (item, index) =>
                `<span
            key="${index}"
            class="testimonial_dot"
              onclick="currentSlide(${index + 1})"
            ></span>`
            )
            .join("")}
        </div>
      </div>`;
  } else if (data.templates === "testimonial_3") {
    return `<div class="apply_max_width_widget">
        <div class="d_flex align_items_center">
          <div class="prev_and_next_btn" onclick="plusSlides(-1)">
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 12h14M5 12l4-4m-4 4 4 4"
              />
            </svg>
          </div>
          <div class="width_100">
            ${data.style.members
              .map(
                (item, index) =>
                  `<div key="${index}" class="mySlides fade">
                <div class="d_flex align_items_center justify_content_between">
                  <div class="d_flex">
                    <img
                      class="testimonial_image_1"
                      src="${item.image}"
                      alt="member"
                      height="100"
                    />
                    <div class="margin_left_10px">
                      <h4>${item.name}</h4>
                      <p>${item.designation}</p>
                    </div>
                  </div>
                  <div>
                    <h1>${item.testimonial}</h1>
                  </div>
                  </div>
                </div>`
              )
              .join("")}
          </div>
          <div class="prev_and_next_btn" onclick="plusSlides(1)" > 
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        </div>
      </div>`;
  } else {
    return `<div>
        <div class="apply_max_width_widget">
          <div>
            <h1>${data.style.content.title}</h1>
            <p>${data.style.content.subtitle}</p>
          </div>
          <div class="d_flex flex_wrap">
            ${data.style.members
              .map(
                (item, index) =>
                  `<div key="${index}" class="testimonial_4_items">
                <h2>${item.testimonial}</h2>
                <div>
                  <h4>${item.name}</h4>
                  <p>${item.designation}</p>
                </div>
              </div>`
              )
              .join("")}
          </div>
        </div>
      </div>`;
  }
};

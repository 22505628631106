import { useEffect } from "react";
import styles from "./index.module.css";
import PNGClass from "../../../../../../assets/png";
import SVGClass from "../../../../../../assets/svg";

const OurTeamTemplate = ({ onChange, value }) => {
  useEffect(() => {
    if (value === undefined) {
      onChange("our_team_1");
    }
  }, [value, onChange]);

  const saveTemplateHandler = (val) => {
    if (val !== value) {
      onChange(val);
    }
  };

  return (
    <div className={`${styles.template}`}>
      <div
        onClick={() => saveTemplateHandler("our_team_1")}
        className={`${styles.box_sizing} ${
          value === "our_team_1" && styles.active
        }`}
      >
        <img src={PNGClass.team1} alt="TEAM 1" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("our_team_2")}
        className={`${styles.box_sizing}  ${
          value === "our_team_2" && styles.active
        }`}
      >
        <img src={PNGClass.team2} alt="TEAM 2" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("our_team_3")}
        className={`${styles.box_sizing}  ${
          value === "our_team_3" && styles.active
        }`}
      >
        <img src={PNGClass.team3} alt="TEAM 3" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("our_team_4")}
        className={`${styles.box_sizing}  ${
          value === "our_team_4" && styles.active
        }`}
      >
        <img src={PNGClass.team4} alt="TEAM 4" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("our_team_5")}
        className={`${styles.box_sizing}  ${
          value === "our_team_5" && styles.active
        }`}
      >
        <img src={PNGClass.team5} alt="TEAM 5" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
    </div>
  );
};

export default OurTeamTemplate;

export const FAQCSS = `
.item_max_width {
    max-width: 400px;
}

.item_style {
    margin: 4px;
    border-radius: 10px;
    background-color: rgb(236, 236, 236);
}

.faq_item {
    padding: 10px;
}

.faqs_2 {
    margin: 0 100px;
}

.additional_question {
    background-color: #2a3342;
    color: white;
    padding: 20px;
}

.additional_question h1 {
    padding: 0;
    margin: 0;
}

.additional_question button {
    padding: 6px 50px;
    background-color: #1aa1f1;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: larger;
}
`;

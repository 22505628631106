import TestimonialRender from "./widgets/render";
import TestimonialTemplates from "./widgets/templates";
import TestimonialMember from "./widgets/fields/testimonial_members";

export const Testimonials = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        content: {
          type: "object",
          label: "Content",
          objectFields: {
            title: {
                type: "text",
                label: "Title",
            },
            subtitle: {
                type: "textarea",
                label: "Subtitle",
            }
          },
        },
        members: {
            type: "custom",
            render: TestimonialMember,
        },
      },
    },
    templates: {
      type: "custom",
      render: TestimonialTemplates,
    },
  },
  defaultProps: {
    style: {
        content: {
            title: "Testimonials from Boostify360 Builder users",
            subtitle: "Here's what our users have to say about us",
        },
        members: [
            {
                id: Date.now().toLocaleString().split(",").join(""),
                name: "Member",
                designation: "CEO",
                testimonial: "Testimonial",
                image: "http://boostify360.b-cdn.net/NEk7K4SZq699921355kv1728317145/1728320801149.png",
            }
        ],
    },
    templates: "testimonial_1",
  },
  render: TestimonialRender,
};

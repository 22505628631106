export const getOurTeamHTML = (data, settings) => {
  const getImages = (startIndex, isLargeFirst) => {
    const largeImage = data.style.details[startIndex];
    const smallImages = data.style.details?.slice(
      startIndex + 1,
      startIndex + 3
    );

    return `<div class="our_team_grid_container">
        ${
          isLargeFirst &&
          largeImage ?
          `<div class="our_team_large_image">
            <img
              class="our_team_images"
              src="${largeImage.image}"
              alt="${largeImage.name}"
            />
            <div class="our_team_overlay">
              <h3>${largeImage.name}</h3>
              <p
                style="color:${settings?.globalSettings?.themeColor};"
              >
                ${largeImage.designation}
              </p>
            </div>
          </div>`: ""
        }

        <div class="our_team_small_images_column">
          ${smallImages
            .map(
              (image, index) =>
                `<div class="our_team_small_image" key="${index}">
              <img
                class="our_team_images"
                src="${image.image}"
                alt="${image.name}"
              />
              <div class="our_team_overlay">
                <h3>${image.name}</h3>
                <p
                  style="color:${settings?.globalSettings?.themeColor};"
                >
                  ${image.designation}
                </p>
              </div>
            </div>`
            )
            .join("")}
        </div>

        ${
          !isLargeFirst &&
          largeImage ?
          `<div class="our_team_large_image">
            <img
              class="our_team_images"
              src="${largeImage.image}"
              alt="${largeImage.name}"
            />
            <div class="our_team_overlay">
              <h3>${largeImage.name}</h3>
              <p
                style="color:${settings?.globalSettings?.themeColor};"
              >
                ${largeImage.designation}
              </p>
            </div>
          </div>` : ""
        }
      </div>`;
  };

  return `<div>
      <div class="apply_max_width_widget">
        <div
          class="${
            data.templates === "our_team_4" || data.templates === "our_team_5"
              ? "d_flex justify_content_between"
              : ""
          }"
        >
          <div class="width_100">
            <h1
              class="${
                data.templates === "our_team_2" ||
                data.templates === "our_team_3"
                  ? "text_align_center"
                  : ""
              }"
            >
              ${data.style.content?.title}
            </h1>
            <p
              class="${
                data.templates === "our_team_2" ||
                data.templates === "our_team_3"
                  ? "text_align_center"
                  : ""
              }"
            >
              ${data.style.content?.description}
            </p>
          </div>
          ${
            data.templates === "our_team_3" ||
            data.templates === "our_team_4" ||
            data.templates === "our_team_5"
              ? `<div class="width_100 d_flex align_items_center justify_content_center">
              <button
                class="our_team_first_button"
                style="background-color:${settings?.globalSettings?.themeColor};"
              >
                ${data.style.first_button.title}
              </button>
              <button
                class="our_team_second_button"
                style="color:${settings?.globalSettings?.themeColor};border:1px solid ${settings?.globalSettings?.themeColor};"
              >
                ${data.style.second_button.title}
              </button>
            </div>`
              : ""
          }
        </div>
        ${
          data.templates === "our_team_5"
            ? `<div>
            ${data.style.details
              ?.map((_, index) =>
                index % 3 === 0 ? getImages(index, index % 6 === 0) : ""
              )
              .join("")}
          </div>`
            : `<div class="d_flex flex_wrap width_100">
            ${data.style.details
              ?.map(
                (item, index) =>
                  `<div
                class="margin_10px our_team_member_item"
                key="${index}"
              >
                <div
                  class="${
                    data.templates === "our_team_2" ||
                    data.templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  } ${
                    data.templates === "our_team_1" ||
                    data.templates === "our_team_2" ||
                    data.templates === "our_team_3"
                      ? "our_team_member_item_123"
                      : ""
                  } ${data.templates === "our_team_4" ? "our_team_4" : ""}"
                >
                  <img src="${item.image}" alt="ourteam_${index}" />
                </div>
                <p
                  class="${
                    data.templates === "our_team_2" ||
                    data.templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  }"
                >
                  <b>${item.name}</b>
                </p>
                <p
                  class="${
                    data.templates === "our_team_2" ||
                    data.templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  }"
                  style="color:${settings?.globalSettings?.themeColor};"
                >
                  ${item.designation}
                </p>
                <p
                  class="${
                    data.templates === "our_team_2" ||
                    data.templates === "our_team_4" ||
                    data.templates === "our_team_5"
                      ? "d_none"
                      : data.templates === "our_team_3"
                      ? "text_align_center"
                      : ""
                  }"
                >
                  ${item.description}
                </p>
                <div
                  class="d_flex ${
                    data.templates === "our_team_2" ||
                    data.templates === "our_team_3"
                      ? "justify_content_center"
                      : ""
                  }"
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                      clip-rule="evenodd"
                    />
                    <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                  </svg>
                </div>
              </div>`
              )
              .join("")}
          </div>`
        }
      </div>
    </div>`;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.render_contact_svg__SQFeR {
    color: white;
    padding: 16px;
    border-radius: 50%;
}

.render_contact_item__IbtY3 h1, .render_contact_item__IbtY3 p {
    padding: 0;
    margin: 0;
}

.render_contact_button__JIs5g {
    border: none;
    color: white;
    padding: 10px 25px;
    border-radius: 10px;
}

.render_subsribe_email__LCC6a {
    padding: 0 80px;
}

.render_subsribe_email__LCC6a input, .render_subsribe_email__LCC6a textarea {
    border: 1px solid var(--bdr-color);
    width: 100%;
}

.render_subsribe_email__LCC6a button {
    border: none;
    padding: 10px 0 !important;
    width: 100%;
    color: white;
    border-radius: 10px;

}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/contact_us/widgets/render/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,WAAW;IACX,YAAY;IACZ,mBAAmB;;AAEvB","sourcesContent":[".contact_svg {\n    color: white;\n    padding: 16px;\n    border-radius: 50%;\n}\n\n.contact_item h1, .contact_item p {\n    padding: 0;\n    margin: 0;\n}\n\n.contact_button {\n    border: none;\n    color: white;\n    padding: 10px 25px;\n    border-radius: 10px;\n}\n\n.subsribe_email {\n    padding: 0 80px;\n}\n\n.subsribe_email input, .subsribe_email textarea {\n    border: 1px solid var(--bdr-color);\n    width: 100%;\n}\n\n.subsribe_email button {\n    border: none;\n    padding: 10px 0 !important;\n    width: 100%;\n    color: white;\n    border-radius: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact_svg": `render_contact_svg__SQFeR`,
	"contact_item": `render_contact_item__IbtY3`,
	"contact_button": `render_contact_button__JIs5g`,
	"subsribe_email": `render_subsribe_email__LCC6a`
};
export default ___CSS_LOADER_EXPORT___;

export const LogoCloudHTML = (data) => {
  return `
        <div style="background-color:#f7f8fa;">
      <div
        class="apply_max_width_widget ${
          data.templates === "logo_cloud_7" || data.templates === "logo_cloud_8"
            ? "d_flex align_items_center"
            : ""
        }"
      >
        <div>
          ${
            data.templates === "logo_cloud_1" ||
            data.templates === "logo_cloud_2" ||
            data.templates === "logo_cloud_3" ||
            data.templates === "logo_cloud_4"
              ? `<p class="text_align_center">${data.style.content.title}</p>`
              : `<div>
              <h1
                class="${
                  data.templates === "logo_cloud_7" ||
                  data.templates === "logo_cloud_8"
                    ? ""
                    : "text_align_center"
                }"
              >
                ${data.style.content.title}
              </h1>
              <p
                class="${
                  data.templates === "logo_cloud_7" ||
                  data.templates === "logo_cloud_8"
                    ? ""
                    : "text_align_center"
                }"
              >
                ${data.style.content.description}
              </p>
            </div>`
          }
        </div>
        <div class="d_flex flex_wrap align_items_center justify_content_evenly height_100">
          ${data.style.details
            .map(
              (item, index) =>
                `<div
            key="${index}"
              class="${
                data.templates === "logo_cloud_1" ||
                data.templates === "logo_cloud_2"
                  ? ""
                  : "logo_cloud_item"
              }"
            >
              <img
                class="${
                  data.templates === "logo_cloud_2" ||
                  data.templates === "logo_cloud_4" ||
                  data.templates === "logo_cloud_6" ||
                  data.templates === "logo_cloud_8"
                    ? "logo_cloud_img_greyscale"
                    : ""
                } logo_cloud_images"
                src="${item.image}"
                alt="logo ${index}"
              />
            </div>`
            )
            .join("")}
        </div>
      </div>
    </div>
    `;
};

import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import { Puck } from "@measured/puck";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
/* -------------------------------------------------------------------------- */
/*                                  OVERRIDES                                 */
/* -------------------------------------------------------------------------- */
import ItemOverride from "../puck_requirements/overrides/item";
import PuckOverride from "../puck_requirements/overrides/puck";
import FieldOverride from "../puck_requirements/overrides/field";
import PreviewOverride from "../puck_requirements/overrides/preview";
import FieldLabelOverride from "../puck_requirements/overrides/field_label";
/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */
import { appConfig } from "../puck_requirements/config";
/* -------------------------------------------------------------------------- */
/*                                    REDUX                                   */
/* -------------------------------------------------------------------------- */
import { changeRootSelection } from "../redux/slices";
import { setCurrentUrl } from "../redux/slices/navigation";
import {
  getGlobalSettingsBySiteIdThunk,
  getSiteBySiteIdThunk,
  isLoggedInThunk,
} from "../redux/thunks";
/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */
import SideBar from "../components/sidebar";
import { getAllPagesBySiteId } from "../apis";
import { exportCodeFileInZip } from "../helper";
import Spinner from "../components/spinner_loader";
import HeaderComponent from "../components/header";
import DeleteModal from "../components/delete_modal";
import PreviewCustomInterface from "../puck_requirements/custom_interfaces/preview";
/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */
import "@measured/puck/puck.css";
import styles from "./App.module.css";
import ErrorModal from "../components/error_modal";

function App() {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const isOnline = useSyncExternalStore(
    subscribe,
    () => navigator.onLine,
    () => true
  );
  const initialData = {
    content: [],
    root: {},
  };
  const puckRef = useRef();
  const APP_VERSION = "1.1.1";
  const navigate = useNavigate();
  const location = useLocation();
  const { siteId } = useParams();
  const stateDispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState(1); // 1 Widgets, 2 Pictures, 3 Global Theme, 4 Settings, 5 Global Settings
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    error: false,
    title: "",
    message: "",
  });
  const siteData = useSelector((state) => state.auth);
  const posts = useSelector((state) => state.posts.posts);
  const settings = useSelector((state) => state.settings);
  const isLoading = useSelector((state) => state.app.isLoading);
  const history = useSelector((state) => state.navigation.history);
  const isFullScreen = useSelector((state) => state.app.isFullScreen);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------ Changing page from header ----------------------- */
  const changePageHandler = (selected) => {
    stateDispatch(
      changeRootSelection({
        header: selected !== 5,
        footer: false,
        headerTemplate: false,
        footerTemplate: false,
      })
    );
    setSelectedPage(selected);
  };

  /* -------------------------- CHECKING APP VERSION -------------------------- */
  useEffect(() => {
    const storedVersion = localStorage.getItem("appVersion");
    if (storedVersion !== APP_VERSION) {
      window.location.assign("https://app.boostify360.com/");
    }
  }, []);

  /* --------------------------- GET SITE BY SITE ID -------------------------- */
  const getSiteBySiteIdHandler = useCallback(async () => {
    try {
      await stateDispatch(
        getSiteBySiteIdThunk({ siteId: siteId || siteData.siteId })
      ).unwrap();
      await stateDispatch(
        getGlobalSettingsBySiteIdThunk({ siteId })
      ).unwrap();
    } catch (e) {}
  }, [siteId, siteData.siteId, stateDispatch]);

  useEffect(() => {
    getSiteBySiteIdHandler();
  }, [getSiteBySiteIdHandler]);

  /* ------------------------ WHETHER ONLINE OR OFFLINE ----------------------- */
  function subscribe(callback) {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
      window.removeEventListener("online", callback);
      window.removeEventListener("offline", callback);
    };
  }

  /* ----------------------------- EXPORTING CODE ----------------------------- */
  const exportCode = async () => {
    try {
    await stateDispatch(getGlobalSettingsBySiteIdThunk({ siteId })).unwrap();
    const response = await getAllPagesBySiteId(siteId);
    const parsePage = (page) => ({
      site_id: page.site_id,
      page_id: page.page_id,
      page_position: page.page_position,
      page_type: page.page_type,
      page_title: page.page_title,
      content: JSON.parse(page.content),
      hasPages: page.hasPages ?? false,
      subpages: (page.subpages ?? []).map(parsePage),
      parent_page_id: page.parent_page_id,
      showMenu: page?.page_status === 0 ? false : true,
    });
    const data = response.pages.map(parsePage);
    await exportCodeFileInZip(
      data,
      siteId || siteData.siteId,
      stateDispatch,
      navigate,
      settings,
      posts.slice(-3),
      setShowModal,
      setError
    );} catch (e) {}
  };

  /* -------------------------------- USEEFFECT ------------------------------- */
  useEffect(() => {
    stateDispatch(
      setCurrentUrl(
        `${window.location.origin}${location.pathname}${location.search}${location.hash}`
      )
    );
  }, [stateDispatch, location.hash, location.search, location.pathname]);

  /* ------------------- NAVIGATION HANDLING AUTHENTICATION ------------------- */
  useEffect(() => {
    if (location.pathname === "/site" || location.pathname === "/site/") {
      navigate("/");
      return;
    }
    if ((!siteId && !siteData.siteId) || siteId === "null") {
      navigate("/error");
      return;
    }
    if (history.length === 0) {
      try {
        stateDispatch(
          isLoggedInThunk({
            userAuth: siteData.userAuth,
            userId: siteData.userId,
            navigate,
          })
        ).unwrap();
      } catch (e) {}
      return;
    }
  }, [
    history,
    location.hash,
    location.search,
    location.pathname,
    navigate,
    siteId,
    siteData.siteId,
    siteData.userAuth,
    siteData.userId,
    stateDispatch,
  ]);

  /* --------------------------- Close Modal Handler -------------------------- */
  const closeModalHandler = () => {
    setShowModal(false);
    setError({ error: false, title: "", message: "" });
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return (
      <div className="width_100 height_100vh d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  } else {
    return isOnline ? (
      <div ref={puckRef} id="root">
        <Puck
          config={appConfig}
          data={initialData}
          overrides={{
            componentItem: ItemOverride,
            fields: ({ children, isLoading, itemSelector }) => (
              <FieldOverride
                children={children}
                isLoading={isLoading}
                selectedPage={selectedPage}
                itemSelector={itemSelector}
              />
            ),
            fieldLabel: FieldLabelOverride,
            preview: PreviewOverride,
            puck: PuckOverride,
          }}
        >
          <div className={styles.root_element_puck}>
            <HeaderComponent
              changePageHandler={changePageHandler}
              selectedPage={selectedPage}
              exportCode={exportCode}
            />
            {isFullScreen ? (
              <div className={`d_flex flex_column ${styles.preview}`}>
                <PreviewCustomInterface />
              </div>
            ) : (
              <div className="d_flex">
                <SideBar />
                <Outlet />
              </div>
            )}
          </div>
          {error.error ? (
            <DeleteModal
              showModal={showModal}
              setShowModal={closeModalHandler}
              deleteHandler={closeModalHandler}
              title={error.title}
              firstLine={error.message}
              secondLine=""
              showFirstButton={false}
              secondButtonTitle="Close"
            />
          ) : (
            <DeleteModal
              showModal={showModal}
              setShowModal={closeModalHandler}
              deleteHandler={closeModalHandler}
              title={error.title}
              firstLine={error.message}
              showSecondLine={false}
              showFirstButton={false}
              secondButtonTitle="Close"
              isDelete={false}
            />
          )}
        </Puck>
        <ErrorModal />
      </div>
    ) : (
      <h1>YOU ARE OFFLINE</h1>
    );
  }
}

export default App;

// import { ImageInput } from "../../../field_objects/image_input";
import WebsiteTarget from "../../../field_objects/website_target";
import LinkWithButton from "../../../fields/custom/link_with_button";
import ContactUsRender from "./widgets/render";
import FAQsRender from "./widgets/render";
import ContactUsTemplate from "./widgets/templates";
// import Rendering from "./rendering";

export const ContactUs = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        content: {
          type: "object",
          label: "Content",
          objectFields: {
            email: {
              type: "text",
              label: "Email",
            },
            phone: {
              type: "number",
              label: "Phone",
            },
            address: {
              type: "textarea",
              label: "Address",
            },
          },
        },
        button: {
          type: "object",
          label: "Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            description: {
              type: "textarea",
              label: "Description",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="first_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName={"first_button"} />
              ),
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: ContactUsTemplate,
    },
  },
  defaultProps: {
    style: {
      content: {
        email: "abc@xyz.com",
        phone: "03XX-XXXXXXX",
        address: "Address Here...",
      },
      button: {
        title: "Visit Boostify360",
        description: "Learn more about Boostify360",
        linkWith: "#",
        openWebAddress: "current",
      },
    },
    templates: "contact_1",
  },
  render: ContactUsRender,
};

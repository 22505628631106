import React, { useEffect } from "react";
import styles from "./index.module.css";
import PNGClass from "../../../../../../assets/png";
import SVGClass from "../../../../../../assets/svg";

const FAQsTemplate = ({ onChange, value }) => {
  useEffect(() => {
    if (value === undefined) {
      onChange("basic_footer");
    }
  }, [value, onChange]);

  const saveTemplateHandler = (val) => {
    if (val !== value) {
      onChange(val);
    }
  };

  return (
    <div className={`${styles.template}`}>
      <div
        onClick={() => saveTemplateHandler("faqs_1")}
        className={`${styles.box_sizing} ${
          value === "faqs_1" && styles.active
        }`}
      >
        <img src={PNGClass.faqs1} alt="FAQ 1" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("faqs_2")}
        className={`${styles.box_sizing}  ${
          value === "faqs_2" && styles.active
        }`}
      >
        <img src={PNGClass.faqs2} alt="FAQ 2" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("faqs_3")}
        className={`${styles.box_sizing}  ${
          value === "faqs_3" && styles.active
        }`}
      >
        <img src={PNGClass.faqs3} alt="FAQ 3" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("faqs_4")}
        className={`${styles.box_sizing}  ${
          value === "faqs_4" && styles.active
        }`}
      >
        <img src={PNGClass.faqs4} alt="FAQ 4" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
    </div>
  );
};

export default FAQsTemplate;

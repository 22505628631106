export const getFAQSHTML = (data, settings) => {
  return `
      <div>
      <div
        class="apply_max_width_widget ${
          data.templates === "faqs_3" ? "d_flex align_items_center " : ""
        }"
      >
        <div
          class="${data.templates === "faqs_3" ? "width_100" : ""} faq_item"
        >
          <h1
            class="${
              data.templates === "faqs_3" || data.templates === "faqs_4"
                ? ""
                : "text_align_center"
            }"
          >
            ${data.style.content.title}
          </h1>
          <p
            class="${
              data.templates === "faqs_3" || data.templates === "faqs_4"
                ? ""
                : "text_align_center"
            }"
          >
            ${data.style.content.description}
          </p>
        </div>
        <div
          class="${
            data.templates === "faqs_2"
              ? "faqs_2"
              : "d_flex justify_content_center align_items_center flex_wrap"
          } ${data.templates === "faqs_3" ? "width_100" : ""}"
        >
          ${data.style.faq
            .map(
              (item, index) =>
                `<div
              key=${index}
              class="d_flex align_items_center justify_content_between ${
                data.templates === "faqs_2" || data.templates === "faqs_3"
                  ? "width_100 padding_10px"
                  : "item_max_width"
              } item_style"
            >
              <div>
                <h4 class="margin_0 padding_0 padding_10px">
                  ${item.question}
                </h4>
                ${
                  data.templates === "faqs_2" || data.templates === "faqs_3" ? (
                    <></>
                  ) : (
                    `<p class="margin_0 padding_0 padding_10px">
                    ${item.answer}
                  </p>`
                  )
                }
              </div>
              ${
                data.templates === "faqs_2" || data.templates === "faqs_3"
                  ? `<div>
                <SVGClass.ArrowDown />
              </div>`
                  : ""
              }
            </div>`
            )
            .join("")}
        </div>
      </div>
      ${
        data.templates === "faqs_4"
          ? `<div class="additional_question">
          <div class="apply_max_width_widget d_flex flex_column align_items_center justify_items_center">
            <h1>Have any additional Questions?</h1>
            <p>
              Boostify360 Builder is a Software application. Boostify360 isn't a
              traditional Software
            </p>
            <button style="background-color:${settings?.globalSettings?.themeColor};">Contact Us</button>
          </div>
        </div>`
          : ""
      }
    </div>
      `;
};

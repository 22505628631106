import TeamDetailsField from "./widgets/fields/team_details";
import OurTeamTemplate from "./widgets/templates";
import OurTeamRender from "./widgets/render";
import WebsiteTarget from "../../../field_objects/website_target";
import LinkWithButton from "../../../fields/custom/link_with_button";

export const OurTeams = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        content: {
          type: "object",
          label: "Team Content",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            description: {
              type: "textarea",
              label: "Description",
            },
          },
        },
        details: {
          type: "custom",
          render: TeamDetailsField,
        },

        buttons: {
          type: "object",
          labels: "First Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="first_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName={"first_button"} />
              ),
            },
          },
        },
        secondButton: {
          type: "object",
          labels: "Second Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="second_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName={"second_button"} />
              ),
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: OurTeamTemplate,
    },
  },
  defaultProps: {
    style: {
      content: {
        title: "Meet Our Team",
        description:
          "Highly professional and capable of running your business across all digital channels",
      },
      first_button: {
        title: "Open Positions",
        linkWith: "#",
        openWebAddress: "current",
      },
      second_button: {
        title: "About Us",
        linkWith: "#",
        openWebAddress: "current",
      },
      details: [
        {
          id: Math.random(),
          name: "Default",
          designation: "CEO & Founder",
          image:
            "https://boostify360.b-cdn.net/4P74Oj21894QsS719AS11720041352/1720809205789.png",
          description: "The mind is everything. What you think you become.",
        },
      ],
    },
    templates: "our_team_1",
  },
  render: OurTeamRender,
};

import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    show: false,
    title: "",
    description: "",
  },
  reducers: {
    showError: (state, action) => {
      state.show = true;
      state.title = action.payload?.title ? action.payload?.title : "Error";
      state.description = action.payload?.description ? action.payload?.description : "Something went wrong";
    },
    hideError: (state) => {
      state.show = false;
      state.title = "";
      state.description = "";
    },
    resettingError: () => {
      return {
        show: false,
        title: "",
        description: "",
      }
    }
  },
});

export const { showError, hideError } = errorSlice.actions;
export default errorSlice.reducer;

import { aboutCSS } from "./about";
import { ContactUsCSS } from "./contact_us";
import { example1CSS } from "./example1";
import { FAQCSS } from "./faq";
import { featuresCSS } from "./features";
import { FooterCSS } from "./footer";
import { galleryCSS } from "./gallery";
import { headerCSS } from "./header";
import { pricingCSS } from "./pricing";
import { TeamCSS } from "./team";
import { TestimonialCSS } from "./testimonials";

export const globalCSS = `
.d_none {
    display: none !important;
}

.d_flex {
    display: flex !important;
}

.d_block {
    display: block !important;
}

.flex_row_reverse {
    flex-direction: row-reverse !important;
}

.flex_row {
    flex-direction: row !important;
}

.flex_column {
    flex-direction: column !important;
}

.justify_content_between {
    justify-content: space-between;
}

.justify_content_center {
    justify-content: center;
}

.justify_content_evenly {
    justify-content: space-evenly;
}

.justify_content_start {
    justify-content: start;
}

.justify_content_end {
    justify-content: end;
}

.justify_content_around {
    justify-content: space-around;
}

.align_items_start {
    align-items: start;
}

.align_items_center {
    align-items: center;
}

.align_items_end {
    align-items: end;
}

.width_50 {
    width: 50%;
}

.width_100 {
  width: 100% !important;
}

.font_italic {
  font-style: italic !important;
}

.text_decoration_none {
text-decoration: none !important;
}

.font_bold {
  font-weight: bold !important;
}

.font_weight_unset {
  font-weight: unset !important;
}

.font_style_unset {
  font-style: unset !important;
}

.text_align_center {
  text-align: center !important;
}

.p_relative {
    position: relative !important;
}

.p_absolute {
    position: absolute !important;
}

.cursor_pointer {
    cursor: pointer !important;
}

.overflow_y_scroll {
    overflow-y: scroll !important;
}

.text_white {
color: white
}
    .field_label {
    font-size: 10px;
    color: #7C808A;
}

.apply_max_width_widget {
    width: 82%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px;
}

.primary_button {
    display: flex;
    align-items: center;
    padding: 1.4vh 1vw;
    background-color: #1aa1f1;
    color:#e7f5fe;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;
    height: 100%;
}

.primary_button:hover {
    background-color: #4392ff; 
    cursor: pointer;
}

.secondary_button {
    display: flex;
    align-items: center;
    padding: 1.4vh 1vw;
    border: 1px solid #1aa1f1;
    background-color:#e7f5fe;
    color: #1aa1f1;
    border-radius: 5px;
    font-size: 0.8rem;
    height: 100%;
}

.secondary_button:hover {
    background-color: #1aa1f1;
    color:#e7f5fe;
    cursor: pointer;
}

.field_dropdown {
    border: 1px solid #e5e5e5;
    position: relative;
    background-color: white;
    border-radius: 6px;
    padding: 8px;
    font-size:12px;
    color: #363A43;
    cursor: pointer;
     position: relative;
}

.field_dropdown_items {
display: none;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 21px;
    left: 0;
    z-index: 1000;
    list-style: none;
}

.field_dropdown:hover .field_dropdown_items {
  display: block;
}

.field_dropdown_items li {
  padding: 8px 16px;
  cursor: pointer;
}

.field_dropdown_items li:hover {
  background-color: #f0f0f0;
}

.margin_vertical_10px {
  margin: 10px 0 !important;
}

.border_none {
  border: none !important;
}

.light_blue_border {
  border: 0.1rem solid lightblue !important;
}

.black_border {
  border: 0.1rem solid black !important;
}

.green_border {
  border: 0.1rem solid #00b786 !important
}

.grey_border {
  border: 0.1rem solid #dcdcdc !important
}

.gray_border {
  border: 0.1rem solid grey !important;
}

.light_grey_border {
  border: 0.1rem solid lightgray !important;
}

.grey_border_custom {
  border: 1px solid rgba(211, 211, 211, 0.2) !important;
}

.green_bottom_border {
  border-bottom: 0.1rem solid #00b786 !important
}

.grey_bottom_border {
  border-bottom: 0.1rem solid #dcdcdc !important
}

.gray_bottom_border {
  border-bottom: 0.1rem solid grey !important;
}

.light_grey_bottom_border {
  border-bottom: 0.1rem solid lightgray !important;
}

.grey_border_bottom_custom {
  border-bottom: 1px solid rgba(211, 211, 211, 0.2) !important;
}

.outline_none {
  outline: none !important;
}

.box_shadow_none {
  box-shadow: none !important;
}


.separater {
  border: 1px solid var(--bdr-color) !important;
}

.separater_bottom {
  border-bottom: 1px solid var(--bdr-color) !important;
}

.cursor_pointer {
    cursor: pointer !important;
}

.normal_rounded {
    border-radius: 0.5rem !important;
}

.overflow_y_scroll {
    overflow-y: scroll !important;
}

.z_index_100000 {
    z-index: 100000 !important;
}

.padding_0 {
  padding: 0 !important;
}

.smaller_padding {
  padding: 0.5rem 0.25rem !important;
}

.small_padding {
  padding: 0.75rem 0.25rem !important;
}

.normal_padding {
  padding: 1rem 0.5rem !important;
}

.large_padding {
  padding: 1rem 1rem !important;
}

.larger_padding {
  padding: 1rem 2rem !important;
}

.vertical_smaller_padding {
  padding: 0.5rem 0 !important
}

.horizontal_smaller_padding {
  padding: 0 0.5rem !important;
}

.vertical_normal_padding {
  padding: 1rem 0 !important;
}

.horizontal_normal_padding {
  padding: 0 0.5rem !important;
}

.equal_0_5_rem_padding {
  padding: 0.5rem 0.5rem !important;
}

.margin_0 {
  margin: 0 !important;
}

.normal_margin {
  margin: 1rem 0.5rem !important;
}

.horizontal_normal_margin {
  margin: 0 0.5rem !important;
}

.vertical_normal_margin {
  margin: 0.5rem 0 !important;
}

.margin_top_10px {
  margin-top: 10px !important;
}

.margin_bottom_10px {
  margin-bottom: 10px !important;
}

.margin_right_10px {
  margin-right: 10px !important;
}

.margin_left_10px {
  margin-left: 10px !important;
}

.margin_horizontal_10px {
  margin: 0 10px !important;
}

.margin_vertical_10px {
  margin: 10px 0 !important;
}

.margin_10px {
  margin: 10px !important;
}

.padding_top_10px {
  padding-top: 10px !important;
}

.padding_bottom_10px {
  padding-bottom: 10px !important;
}

.padding_right_10px {
  padding-right: 10px !important;
}

.padding_left_10px {
  padding-left: 10px !important;
}

.padding_horizontal_10px {
  padding: 0 10px !important;
}

.padding_vertical_10px {
  padding: 10px 0 !important;
}

.padding_10px {
  padding: 10px !important;
}

.footer_input_button {
    margin-top: 20px;
}

.footer_input {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 10px 15px;
    border: 1px solid #e5e5e5;
}

.footer_input:focus-visible {
    outline: none;
}

.subscribe_button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px 15px;
    border: none;
    background-color: #1aa1f1;
    color: white;
}

.f_facebook {
    color: #1877f2;
}

.f_instgram {
    color: palevioletred;
    margin: 0 20px;
}

.f_linkedin {
    color: #0a66c2;
}

.flex_wrap {
  flex-wrap: wrap;
}

${headerCSS} ${aboutCSS} ${example1CSS} ${featuresCSS} ${galleryCSS} ${pricingCSS} ${FooterCSS} ${FAQCSS} ${ContactUsCSS} ${TeamCSS} ${TestimonialCSS}
`;

import { usePuck } from "@measured/puck";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import GlobalSettings from "../../fields/custom/global_settings";
import { changeRootSelection } from "../../../redux/slices";
import styles from "./index.module.css";
import HeaderTemplate from "../../fields/widgets/header/template";

const FieldComponent = ({
  children,
  isLoading,
  itemSelector,
  selectedPage,
}) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const componentRef = useRef();
  const { appState } = usePuck();
  const location = useLocation();
  const stateDispatch = useDispatch();
  const [isStylesOpen, setIsStylesOpen] = useState(true);
  const [componentData, setComponentData] = useState("");
  const rootSelection = useSelector((state) => state.app.rootSelection);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Use Effect ------------------------------- */
  useEffect(() => {
    if (
      appState.data.content.length !== 0 &&
      appState.ui.itemSelector &&
      !location.pathname.includes("theme")
    ) {
      stateDispatch(
        changeRootSelection({
          header: false,
          footer: false,
          headerTemplate: false,
          footerTemplate: false,
        })
      );
      setComponentData(
        appState.data.content[appState.ui.itemSelector?.index]?.type
      );
    } else if (
      (rootSelection?.header || rootSelection?.headerTemplate) &&
      !location.pathname.includes("theme")
    ) {
      setComponentData("header");
    } else if (
      (rootSelection?.footer || rootSelection?.footerTemplate) &&
      !location.pathname.includes("theme")
    ) {
      setComponentData("footer");
    } else if (location.pathname.includes("theme")) {
      setComponentData("Global Settings");
    } else {
      setComponentData("");
    }
  }, [
    appState.data.content,
    appState.ui.itemSelector,
    stateDispatch,
    rootSelection?.footer,
    rootSelection?.header,
    rootSelection?.footerTemplate,
    rootSelection?.headerTemplate,
    selectedPage,
    location,
  ]);

  /* ------------------ Change options for header and footer ------------------ */
  const changeOptionsHandler = (obj) => {
    stateDispatch(
      changeRootSelection({
        header: rootSelection.headerTemplate ? true : false,
        footer: rootSelection.footerTemplate ? true : false,
        headerTemplate: rootSelection.header ? true : false,
        footerTemplate: rootSelection.footer ? true : false,
      })
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                             Rendering Functions                            */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- Render Header Field -------------------------- */
  const renderHeader = () => {
    return children.find((child) => child.props.field.label === "Header");
  };

  /* --------------------------- Render Footer Field -------------------------- */
  const renderFooter = () => {
    return children.find((child) => child.props.field.label === "Footer");
  };

  /* ------------------------- Render Template Header ------------------------- */
  const renderHeaderTemplate = () => {
    return children.find(
      (child) => child.props.field.label === "Header Template"
    );
  };

  /* ------------------------- Render Template Footer ------------------------- */
  const renderFooterTemplate = () => {
    return children.find(
      (child) => child.props.field.label === "Footer Template"
    );
  };

  /* --------------------------- Render Styles Field -------------------------- */
  const renderStyles = () => {
    return children.find((child) => child.props.field.label === "Styles");
  };

  /* -------------------------- Render Template Field ------------------------- */
  const renderTemplates = () => {
    return children.find((child) => child.props.field.label === "Templates");
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div ref={componentRef}>
      <div className={styles.widget_name}>{componentData}</div>
      {children[0].key !== "page_header" &&
        !location.pathname.includes("theme") && (
          <>
            {children[0].key !== "page_title" ? (
              <div className={`d_flex ${styles.tabs}`}>
                <div
                  className={`cursor_pointer ${styles.tab_items} ${
                    isStylesOpen && styles.active
                  }`}
                  onClick={() => setIsStylesOpen(true)}
                >
                  Content
                </div>
                <div
                  className={`cursor_pointer ${styles.tab_items} ${
                    !isStylesOpen && styles.active
                  }`}
                  onClick={() => setIsStylesOpen(false)}
                >
                  Templates
                </div>
              </div>
            ) : null}
            {isStylesOpen
              ? renderStyles()
              : !isStylesOpen
              ? renderTemplates()
              : null}
          </>
        )}
      {children[0].key === "page_header" &&
      !location.pathname.includes("theme") ? (
        <>
          {children[0].key !== "page_title" &&
          (rootSelection.header ||
            rootSelection.footer ||
            rootSelection.headerTemplate ||
            rootSelection.footerTemplate) &&
          !location.pathname.includes("theme") ? (
            <div className={`d_flex ${styles.tabs}`}>
              <div
                className={`cursor_pointer ${styles.tab_items} ${
                  (rootSelection.header || rootSelection.footer) &&
                  styles.active
                }`}
                onClick={changeOptionsHandler}
              >
                Content
              </div>
              <div
                className={`cursor_pointer ${styles.tab_items} ${
                  (rootSelection.headerTemplate ||
                    rootSelection.footerTemplate) &&
                  styles.active
                }`}
                onClick={changeOptionsHandler}
              >
                Template
              </div>
            </div>
          ) : null}
          <div className="margin_bottom_10px">
            {rootSelection.header
              ? renderHeader()
              : rootSelection.footer
              ? renderFooter()
              : rootSelection.headerTemplate
              ? // ? <HeaderTemplate />
                renderHeaderTemplate()
              : rootSelection.footerTemplate
              ? renderFooterTemplate()
              : null}
          </div>
        </>
      ) : null}
      {location.pathname.includes("theme") && <GlobalSettings />}
    </div>
  );
};

export default FieldComponent;

import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SVGClass from "../../../../../../../assets/svg";

const FAQField = ({ value, onChange }) => {
  const [selectedInput, setSelectedInput] = useState(null);
  useEffect(() => {
    if (value === undefined) {
      onChange([]);
    }
  }, [value, onChange]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedInputs = Array.from(value.inputs);
    const [removed] = reorderedInputs.splice(result.source.index, 1);
    reorderedInputs.splice(result.destination.index, 0, removed);

    onChange({ ...value, inputs: reorderedInputs });
  };

  const addingFaq = () => {
    const validValue = value;
    if (validValue !== undefined && validValue !== null && validValue) {
      onChange([
        ...validValue,
        {
          id: Math.random(),
          question: "What is Boostify360 Builder?",
          answer: "Boostify360 Builder is a builder to create a website",
        },
      ]);
    } else {
      onChange([
        {
          id: Math.random(),
          question: "What is Boostify360 Builder?",
          answer: "Boostify360 Builder is a builder to create a website",
        },
      ]);
    }
  };

  const questionChangeHandler = (index, label) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, question: label } : input
    );
    onChange(newInputs);
  };

  const answerChangeHandler = (index, label) => {
    const newInputs = value.map((input, i) =>
      i === index ? { ...input, answer: label } : input
    );
    onChange(newInputs);
  };

  const deleteChangeHandler = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    const newInputs = value?.filter((input) => input.id !== index);
    onChange(newInputs);
  };

  const selectedItemHandler = (item) => {
    if (item.id === selectedInput?.id) {
      setSelectedInput(null);
    } else {
      setSelectedInput(item);
    }
  };

  return (
    <div>
      <div className="field_heading margin_vertical_10px">FAQS</div>
      <div>
        <button
          className="primary_button width_100 d_flex justify_content_center margin_vertical_10px"
          onClick={addingFaq}
        >
          Add FAQ
        </button>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {value &&
                  value?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="cursor_pointer"
                        >
                          <div
                            className="gray_border normal_rounded padding_10px margin_vertical_10px d_flex justify_content_between align_items_center"
                            onClick={() => selectedItemHandler(item)}
                          >
                            {item.question}
                            <div
                              onClick={(e) => deleteChangeHandler(item.id, e)}
                            >
                              <SVGClass.Trash />
                            </div>
                          </div>
                          {selectedInput && selectedInput?.id === item.id && (
                            <div className="gray_border normal_rounded padding_10px">
                              <div className="field_heading">Question</div>
                              <div className="field_label_field_input__u5-69">
                                <input
                                  className="_Input-input_1qi5b_46 margin_vertical_10px"
                                  autoComplete="off"
                                  type="text"
                                  value={item.question || ""}
                                  onChange={(e) =>
                                    questionChangeHandler(index, e.target.value)
                                  }
                                />
                              </div>
                              <div className="field_heading">Answer</div>
                              <div className="field_label_field_input__u5-69">
                                <textarea
                                  className="_Input-input_1qi5b_46 margin_vertical_10px"
                                  autoComplete="off"
                                  type="text"
                                  value={item.answer || ""}
                                  onChange={(e) =>
                                    answerChangeHandler(index, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="separater margin_vertical_10px"></div>
    </div>
  );
};

export default FAQField;


export const TextColorObject = {
  type: "custom",
  label: "Text Color",
  render: ({ name, onChange, value }) => {
    const handleColorChange = (e) => {
      const color = e.currentTarget.value;
      onChange(color);
    };

    return (
      <div
        className="d_flex justify_content_start align_items_center color_input p_relative"
      >
        <input
          type="color"
          value={value}
          name={name}
          onChange={handleColorChange}
        />{" "}
        <span className="field_color_label">Text Color</span>
      </div>
    );
  },
};

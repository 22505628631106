import { useSelector } from "react-redux";
import SVGClass from "../../../../../../assets/svg";
import styles from "./index.module.css";

const FAQsRender = ({ style, templates }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <div>
      <div
        className={`apply_max_width_widget ${
          templates === "faqs_3" ? "d_flex align_items_center " : ""
        }`}
      >
        <div
          className={`${templates === "faqs_3" ? "width_100" : ""} ${
            styles.faq_item
          }`}
        >
          <h1
            className={` ${
              templates === "faqs_3" || templates === "faqs_4"
                ? ""
                : "text_align_center"
            }`}
          >
            {style.content.title}
          </h1>
          <p
            className={` ${
              templates === "faqs_3" || templates === "faqs_4"
                ? ""
                : "text_align_center"
            }`}
          >
            {style.content.description}
          </p>
        </div>
        <div
          className={`${
            templates === "faqs_2"
              ? styles.faqs_2
              : "d_flex justify_content_center align_items_center flex_wrap"
          } ${templates === "faqs_3" ? "width_100" : ""}`}
        >
          {style.faq.map((item, index) => (
            <div
              key={index}
              className={`d_flex align_items_center justify_content_between ${
                templates === "faqs_2" || templates === "faqs_3"
                  ? "width_100 padding_10px"
                  : styles.item_max_width
              } ${styles.item_style}`}
            >
              <div>
                <h4 className="margin_0 padding_0 padding_10px">
                  {item.question}
                </h4>
                {templates === "faqs_2" || templates === "faqs_3" ? (
                  <></>
                ) : (
                  <p className="margin_0 padding_0 padding_10px">
                    {item.answer}
                  </p>
                )}
              </div>
              {templates === "faqs_2" || templates === "faqs_3" ? (
                <div>
                  <SVGClass.ArrowDown />
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
      {templates === "faqs_4" ? (
        <div className={styles.additional_question}>
          <div className="apply_max_width_widget d_flex flex_column align_items_center justify_items_center">
            <h1>Have any additional Questions?</h1>
            <p>
              Boostify360 Builder is a Software application. Boostify360 isn't a
              traditional Software
            </p>
            <button
              style={{
                backgroundColor: `${settings?.globalSettings?.themeColor}`,
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FAQsRender;

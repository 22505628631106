import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SVGClass from "../../../../assets/svg";
import styles from "./index.module.css";
import { globalCSS } from "../../../../extracted_html_and_css/css/global";
import { postGlobalSettingsBySiteIdThunk } from "../../../../redux/thunks";
import { useParams } from "react-router-dom";
import { BlockPicker } from "react-color";

const GlobalSettings = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const { siteId } = useParams();
  // const themeColorRef = useRef();
  const settings = useSelector((state) => state.settings);
  const [localThemeColor, setLocalThemeColor] = useState(
    settings?.globalSettings?.themeColor || "#ffffff"
  );
  const [isColorPickerVisible, setColorPickerVisible] = useState(false);
  const fontFamilyList = [
    { label: "Courier", value: "'Courier New', Courier, monospace" },
    {
      label: "Franklin Gothic Medium",
      value: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
    },
    {
      label: "Lucida Sans",
      value:
        "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
    },
    {
      label: "Gill Sans",
      value: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
    },
    {
      label: "Segoe UI",
      value: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    },
    { label: "Times New Roman", value: "'Times New Roman', Times, serif" },
    {
      label: "Trebuchet MS",
      value:
        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
    },
    { label: "Arial", value: "Arial, Helvetica, sans-serif" },
    {
      label: "Cambria",
      value: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
    },
    { label: "Georgia", value: "Georgia, 'Times New Roman', Times, serif" },
    {
      label: "Impact",
      value: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    },
    { label: "Verdana", value: "Verdana, Geneva, Tahoma, sans-serif" },
    { label: "cursive", value: "cursive" },
    { label: "fantasy", value: "fantasy" },
    { label: "monospace", value: "monospace" },
    { label: "sans-serif", value: "sans-serif" },
    { label: "serif", value: "serif" },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  const updateGlobalSettings = async (updatedSettings) => {
    const formData = new FormData();
    formData.append(
      "site_header",
      JSON.stringify({
        headCode: settings.header.headCode,
        headerObject: settings.header.headerObject,
        headerTemplate: settings.header.headerTemplate || "basic_header",
      })
    );
    formData.append(
      "site_footer",
      JSON.stringify({
        footCode: settings.footer.footCode,
        footerObject: settings.footer.footerObject,
        footerTemplate: settings.footer.footerTemplate || "basic_footer",
      })
    );
    formData.append("site_css", settings.style || globalCSS);
    formData.append("global_settings", JSON.stringify(updatedSettings));
    await dispatch(
      postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
    ).unwrap();
  };

  /* --------------------------- Font Change Handler -------------------------- */
  const handleFontChange = (event) => {
    const selectedFont = event.target.value;
    const updatedSettings = {
      ...settings.globalSettings,
      fontFamily: selectedFont,
    };
    updateGlobalSettings(updatedSettings);
  };

  // const inputHandler = (e) => {
  //   // e.preventDefault();
  //   const c = e.target.value;
  //   setLocalThemeColor(c);
  //   // const updatedSettings = {
  //   //   ...settings.globalSettings,
  //   //   themeColor: c,
  //   // };
  //   // updateGlobalSettings(updatedSettings);
  //   // updateGlobalSettings(updatedSettings);
  // };

  const colorSaveHandler = () => {
    const updatedSettings = {
      ...settings.globalSettings,
      themeColor: localThemeColor,
    };
    updateGlobalSettings(updatedSettings);
  };

  // const handleRefClick = () => {
  //   themeColorRef.current.click();
  // };

  const colorHandler = (color) => {
    setLocalThemeColor(color);
    // const updatedSettings = {
    //   ...settings.globalSettings,
    //   themeColor: color,
    // };
    // updateGlobalSettings(updatedSettings);
  };

  // const renderColor = ({
  //   colorInputRef,
  //   value,
  //   name,
  //   inputHandler,
  //   handleClick,
  //   colorChanger,
  // }) => (

  // );

  const handleColorChange = (color) => {
    setLocalThemeColor(color.hex);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="separater_bottom margin_bottom_10px" />
      <div className="field_label">Font Family</div>
      <select
        id="root_header_border_style"
        className="_Input-input_1qi5b_46"
        value={settings?.globalSettings?.fontFamily}
        onChange={handleFontChange}
      >
        {fontFamilyList.map((option, index) => (
          <option key={index} label={option.label} value={option.value} />
        ))}
      </select>
      <div className="field_label">Theme Color</div>
      <div
        className={`d_flex justify_content_start align_items_center color_input`}
      >
        <div>
          <div className="d_flex margin_top_10px p_relative">
            {/* <input
              type="color"
              ref={themeColorRef}
              className="d_none p_absolute"
              style={{
                opacity: "0",
                top: "0",
                left: "0",
                width: "0",
                height: "0",
                pointerEvents: "none",
              }}
              onChange={(e) => inputHandler(e)}
              value={settings?.globalSettings?.themeColor}
              // name="theme"
            /> */}
            <div
              // onClick={handleRefClick}
              onClick={() => setColorPickerVisible(!isColorPickerVisible)}
              className={`${styles.plus_button} margin_right_10px`}
            >
              <SVGClass.Plus />
            </div>
            {isColorPickerVisible && (
              <div
                className={`styles.color_picker p_absolute`}
                style={{
                  top: "0",
                  left: "0",
                }}
              >
                <BlockPicker
                  color={localThemeColor}
                  onChangeComplete={handleColorChange}
                />
                <button
                  className="width_100 primary_button margin_top_10px d_flex justify_content_center"
                  onClick={colorSaveHandler}
                >
                  Save Theme Color
                </button>
              </div>
            )}
            <div
              onClick={() => colorHandler("#ffc0cb")}
              className={`${styles.plus_button} ${styles.pink_color} margin_right_10px`}
            ></div>
            <div
              onClick={() => colorHandler("#ff0000")}
              className={`${styles.plus_button} ${styles.red_color} margin_right_10px`}
            ></div>
            <div
              onClick={() => colorHandler("#008000")}
              className={`${styles.plus_button} ${styles.green_color} margin_right_10px`}
            ></div>
            <div
              onClick={() => colorHandler("#0000ff")}
              className={`${styles.plus_button} ${styles.blue_color} margin_right_10px`}
            ></div>
            <div
              onClick={() => colorHandler("#800080")}
              className={`${styles.plus_button} ${styles.purple_color} margin_right_10px`}
            ></div>
            <div
              onClick={() => colorHandler("#ffa500")}
              className={`${styles.plus_button} ${styles.orange_color} margin_right_10px`}
            ></div>
          </div>
        </div>
        {/* {renderColor({
          colorInputRef: themeColorRef,
          value: settings?.globalSettings?.themeColor,
          name: "theme",
          inputHandler: inputHandler,
          handleClick: handleRefClick,
          colorChanger: colorHandler,
          })} */}
      </div>
    </>
  );
};

export default GlobalSettings;

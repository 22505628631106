import { Form } from "./form";
import { About } from "./about";
import { Blogs } from "./blogs";
import { Button } from "./button";
import { Gallery } from "./gallery";
import { Pricing } from "./pricing";
import { TextBox } from "./text_box";
import { Example1 } from "./example1";
import { Features } from "./features";
import { ColumnBox } from "./column_box";
import { ImageWidget } from "./image_widget";
import { VerticalSpacing } from "./vertical_spacing";
import { FAQs } from "./faqs";
import { ContactUs } from "./contact_us";
import { OurTeams } from "./our_team";
import { LogoCloud } from "./logo_cloud";
import { Testimonials } from "./testimonials";

export const widgetsConfig = {
  HeroSection: Example1,
  FAQs: FAQs,
  ContactUs: ContactUs,
  Teams: OurTeams,
  Features: Features,
  LogoCloud: LogoCloud,
  Testimonials: Testimonials,
  Form: Form,
  Blog: Blogs,
  About: About,
  // Button: Button,
  Gallery: Gallery,
  // TextBox: TextBox,
  Pricing: Pricing,
  // ColumnBox: ColumnBox,
  // ImageWidget: ImageWidget,
  // verticalSpacing: VerticalSpacing,
  Stats: TextBox,
  Services: TextBox,
  // ContactForm: TextBox,
  // Portfolio: TextBox,
  // Video: TextBox,
  // Events: TextBox,
  // Promotion: TextBox,
  Plans: TextBox,
  Social: TextBox,
  Slider: TextBox,
  NumberCounter: TextBox,
  Map: TextBox,
  ClickToCall: TextBox,
  // Table: TextBox,
  // Tabs: TextBox,
  BeforeAndAfter: TextBox,
  // FileDownload: TextBox,
};

import React from "react";
import styles from "./index.module.css";

const LogoCloudRender = ({ style, templates }) => {
  return (
    <div
      style={{
        backgroundColor: "#f7f8fa",
      }}
    >
      <div
        className={`apply_max_width_widget ${
          templates === "logo_cloud_7" || templates === "logo_cloud_8"
            ? "d_flex align_items_center"
            : ""
        }`}
      >
        <div>
          {templates === "logo_cloud_1" ||
          templates === "logo_cloud_2" ||
          templates === "logo_cloud_3" ||
          templates === "logo_cloud_4" ? (
            <p className="text_align_center">{style.content.title}</p>
          ) : (
            <div>
              <h1
                className={`${
                  templates === "logo_cloud_7" || templates === "logo_cloud_8"
                    ? ""
                    : "text_align_center"
                }`}
              >
                {style.content.title}
              </h1>
              <p
                className={`${
                  templates === "logo_cloud_7" || templates === "logo_cloud_8"
                    ? ""
                    : "text_align_center"
                }`}
              >
                {style.content.description}
              </p>
            </div>
          )}
        </div>
        <div className="d_flex flex_wrap align_items_center justify_content_evenly height_100">
          {style.details.map((item, index) => (
            <div
              className={
                templates === "logo_cloud_1" || templates === "logo_cloud_2"
                  ? ""
                  : styles.logo_item
              }
            >
              <img
                className={`${
                  templates === "logo_cloud_2" ||
                  templates === "logo_cloud_4" ||
                  templates === "logo_cloud_6" ||
                  templates === "logo_cloud_8"
                    ? styles.img_greyscale
                    : ""
                } ${styles.logo_images}
                   `}
                src={item.image}
                alt="logo"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoCloudRender;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.render_testimonial_image_1__3MDju {
    border-radius: 50%;
}

.render_testimonial_dot__FquTr {
    cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.render_active__d78Hs, .render_dot__KsCg-:hover {
    background-color: #717171;
  }

  .render_testimonial_image_2__vB08l{
    border-radius: 20px;
  }


  .render_prev_and_next_btn__rul20 {
    margin: 10px 40px;
    padding: 10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .render_testimonial_4_items__VmVFe {
    min-width: 250px; 
    max-width: 250px;
    min-height: 250px;
    max-height: 250px;
    padding: 15px;
    background-color: white;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/testimonials/widgets/render/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;EACjB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,sCAAsC;AACxC;AACA;IACI,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;EACrB;;;EAGA;IACE,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC","sourcesContent":[".testimonial_image_1 {\n    border-radius: 50%;\n}\n\n.testimonial_dot {\n    cursor: pointer;\n  height: 15px;\n  width: 15px;\n  margin: 0 2px;\n  background-color: #bbb;\n  border-radius: 50%;\n  display: inline-block;\n  transition: background-color 0.6s ease;\n}\n.active, .dot:hover {\n    background-color: #717171;\n  }\n\n  .testimonial_image_2{\n    border-radius: 20px;\n  }\n\n\n  .prev_and_next_btn {\n    margin: 10px 40px;\n    padding: 10px;\n    background-color: white;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n\n  .testimonial_4_items {\n    min-width: 250px; \n    max-width: 250px;\n    min-height: 250px;\n    max-height: 250px;\n    padding: 15px;\n    background-color: white;\n    margin: 10px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"testimonial_image_1": `render_testimonial_image_1__3MDju`,
	"testimonial_dot": `render_testimonial_dot__FquTr`,
	"active": `render_active__d78Hs`,
	"dot": `render_dot__KsCg-`,
	"testimonial_image_2": `render_testimonial_image_2__vB08l`,
	"prev_and_next_btn": `render_prev_and_next_btn__rul20`,
	"testimonial_4_items": `render_testimonial_4_items__VmVFe`
};
export default ___CSS_LOADER_EXPORT___;

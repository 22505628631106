export const TeamCSS = `.our_team_member_item {
    max-width: 265px;
    max-height: 300px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 20px 10px;
}

.our_team_member_item img {
    max-height: 100px;
}

.our_team_first_button {
    padding: 10px 25px;
    border: none;
    border-radius: 20px;
    color: white;
}
.our_team_second_button {
    padding: 10px 25px;
    margin-left: 20px;
    border-radius: 20px;
}

.our_team_member_item_123 img {
    border-radius: 50%;
}

.our_team_4 img {
    border-radius: 0%;
    max-height: 200px;
    width: 100%;
    max-width: 255px;
    min-width: 255px;
}


.our_team_grid_container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 70% 30%; 
    gap: 20px;
    margin-bottom: 20px; /* Add space between rows */
  }

  .our_team_grid_container:nth-child(even) {
    grid-template-columns: 30% 70%;
  }
  
  .our_team_large_image {
    position: relative;
    max-height: 500px;
    min-height: 500px;
  }
  
  .our_team_small_images_column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .our_team_small_image, .our_team_large_image {
    position: relative;
  }
  
  .our_team_images {
    width: 100%;
    height: auto;
    display: block;
    object-fit:fill;
    max-height: 500px;
    border-radius: 20px;
  }
  
  /* Overlay styles for name and designation */
  .our_team_overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%);
    padding: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    box-sizing: border-box;
  }`;
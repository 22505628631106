import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  deleteFileThunk,
  getFileLinksWithSiteIdThunk,
  uploadFileThunk,
} from "../../redux/thunks";
import SVGClass from "../../assets/svg";
import Spinner from "../../components/spinner_loader";
import DeleteModal from "../../components/delete_modal";
import styles from "./index.module.css";

const GalleryImage = ({ src, alt, width, heigth }) => {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => setLoaded(true);
  return (
    <div>
      {/* {!loaded && (
        <div className="width_100 height_100 d_flex justify_content_center align_items_center">
          <Spinner />
        </div>
      )} */}
      <div className="d_flex justify_content_center align_item_center height_100" style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
        {/* <img src={src} alt={alt} height="200px" width="300px" loading="lazy" onLoad={handleLoad}   style={{ objectFit: "cover" }}/> */}
      </div>
    </div>
  );
};

const GalleryPage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const fileInputRef = useRef(null);
  const stateDispatch = useDispatch();
  const { media, isLoading } = useSelector((state) => state.media);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingData, setDeletingData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const getFiles = async () => {
      try {
        await stateDispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
      } catch (err) {}
    };
    getFiles();
  }, [siteId, stateDispatch]);

  const deleteModalHandler = (e, item) => {
    e.stopPropagation();
    setDeleteModalOpen(true);
    setDeletingData(item);
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingData(null);
  };

  /* ------------------------------ DELETE MEDIA ------------------------------ */
  const deleteGalleryItem = async (url) => {
    try {
      const fileName = url.split("/").pop();
      await stateDispatch(deleteFileThunk({ fileName, siteId })).unwrap();
    } catch (err) {}
    cancelDelete();
  };

  /* ------------------------------- HANDLE FILE ------------------------------ */
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        await stateDispatch(
          uploadFileThunk({ selectedFile: file, siteId })
        ).unwrap();
      } catch (e) {}
    }
  };

  /* --------------------- HANDLE INPUT REFERENCE CLICKED --------------------- */
  const handleClick = () => {
    fileInputRef.current.click();
  };

  /* ------------------------ OPEN IMAGE IN FULL SCREEN ----------------------- */
  const openImageInModal = (index) => {
    setSelectedImageIndex(index);
  };

  /* ----------------------- CLOSE IMAGE IN FULL SCREEN ----------------------- */
  const closeImageModal = () => {
    setSelectedImageIndex(null);
  };

  /* ---------------------------- NAVIGATION IMAGE ---------------------------- */
  const navigateImage = (direction) => {
    if (selectedImageIndex !== null) {
      let newIndex = selectedImageIndex + direction;
      if (newIndex < 0) {
        newIndex = media.length - 1;
      } else if (newIndex >= media.length) {
        newIndex = 0;
      }
      setSelectedImageIndex(newIndex);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      try {
        await stateDispatch(
          uploadFileThunk({ selectedFile: file, siteId })
        ).unwrap();
      } catch (e) {}
    }
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return (
      <div className="width_100 calculated_height d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  } else if (media.length === 0) {
    return (
      <div
        className={`width_100 calculated_height d_flex justify_content_center align_items_center ${styles.not_found}`}
      >
        <div
          className={styles.applying_max_width}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            className="d_none"
            onChange={handleFileChange}
            accept="image/*"
          />
          <div
            className={`cursor_pointer ${styles.not_found_container}${
              isDragging ? styles.dragging : ""
            }`}
            onClick={handleClick}
          >
            <div
              className={`d_flex flex_column align_items_center ${styles.border_dotted}`}
            >
              <div className={styles.svg_container}>
                <SVGClass.DropMedia />
              </div>
              <div className={styles.no_media_title}>
                Drop your image here, or <span>browse</span>
              </div>
              <div className={styles.no_mdeia_subtitle}>
                Drop your image here, or browse
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.gallery_outer}>
        <div className={`white_background ${styles.heading_container}`}>
          <div className={`apply_max_width ${styles.heading}`}>
            <span>Media</span>
          </div>
        </div>
        <div className={`${styles.gallery_list} apply_max_width`}>
          <input
            type="file"
            ref={fileInputRef}
            className="d_none"
            onChange={handleFileChange}
            accept="image/*"
          />

          <div className={styles.gallery} id="gallery">
            <div
              className={`${styles.gallery_item}  ${styles.gallery_add}`}
              style={{
                gridRowEnd: "span 8",
              }}
              onClick={handleClick}
            >
              <div>
                <SVGClass.Gallery30 />
              </div>{" "}
              <div className="margin_top_10px">Add Media</div>
            </div>
            {media && media.length > 0 ? (
              media.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.gallery_item}
                    style={{
                      gridRowEnd: "span 8",
                      backgroundImage: `url(${item.media_url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => openImageInModal(index)}
                  >
                    {/* <GalleryImage src={item.media_url} alt={item.media_id} /> */}
                    <div
                      className={styles.gallery_action}
                      onClick={(e) => deleteModalHandler(e, item.media_url)}
                    >
                      <SVGClass.Trash />
                    </div>
                  </div>
                );
              })
            ) : (
              <h1 className="text_align_center">NO MEDIA FOUND</h1>
            )}
          </div>
          {selectedImageIndex !== null && (
            <div className={styles.modal_backdrop} onClick={closeImageModal}>
              <div className={styles.image_modal}>
                <img
                  src={media[selectedImageIndex].media_url}
                  alt={media[selectedImageIndex].media_id}
                  className={styles.modal_image}
                />
                <span className={styles.close_modal} onClick={closeImageModal}>
                  &times;
                </span>
                <div className={styles.modal_navigation}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigateImage(-1);
                    }}
                  >
                    Previous &#10094;
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigateImage(1);
                    }}
                  >
                    &#10095; Next
                  </button>
                </div>
              </div>
            </div>
          )}
          <DeleteModal
            showModal={isDeleteModalOpen}
            setShowModal={cancelDelete}
            cancelDelete={cancelDelete}
            deleteHandler={() => deleteGalleryItem(deletingData)}
          />
        </div>
      </div>
    );
  }
};

export default GalleryPage;

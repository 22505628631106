import { createAsyncThunk } from "@reduxjs/toolkit";
import * as apiService from "../../apis";
import { findPageByPath } from "../../helper";
import { showError } from "../slices/error";

/* ---------------------------- GET SITE DETAILS ---------------------------- */
export const getSiteBySiteIdThunk = createAsyncThunk(
  "app/getSiteBySiteId",
  async ({ siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getSiteBySiteId(siteId);
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred.",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred.");
    }
  }
);

/* ------------------------------ ADD NEW PAGE ------------------------------ */
export const addNewPageBySiteIdThunk = createAsyncThunk(
  "pages/addNewPageBySiteId",
  async ({ data, navigate }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await apiService.addNewPageBySiteId(data);
      if (response?.success === true) {
        const authState = getState().auth;
        await dispatch(
          getAllPagesBySiteIdThunk({
            siteId: authState.siteId,
            navigate,
          })
        ).unwrap();
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An Error occured while adding the page.",
          })
        );
        return rejectWithValue(
          response?.message || "An Error occured while adding the page."
        );
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred.",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred.");
    }
  }
);

/* ------------------------------ GET ALL PAGES ----------------------------- */
export const getAllPagesBySiteIdThunk = createAsyncThunk(
  "pages/getAllPagesBySiteId",
  async ({ siteId, navigate }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const siteIdFromState = state.auth.siteId;
      if (!siteId && !siteIdFromState) {
        navigate("/error", { replace: true });
        return {
          pages: [],
          selectedPage: {
            id: null,
            path: [],
          },
        };
      }
      const response = await apiService.getAllPagesBySiteId(
        siteId || siteIdFromState
      );
      if (!response) {
        dispatch(
          showError({
            title: "Response Error",
            description: "No response from server.",
          })
        );
        return rejectWithValue("Error Occured");
      }
      if (
        response?.success === true &&
        response?.pages &&
        response.pages.length > 0
      ) {
        const { pages } = response;
        const parsePage = (page) => ({
          site_id: page.site_id,
          page_id: page.page_id,
          page_position: page.page_position,
          page_type: page.page_type,
          page_title: page.page_title,
          content: JSON.parse(page.content),
          hasPages: page.hasPages ?? false,
          subpages: (page.subpages ?? []).map(parsePage),
          parent_page_id: page.parent_page_id,
          showMenu: page?.page_status === 0 ? false : true,
        });
        const data = pages.map(parsePage);
        const selectedPage = findPageByPath(
          data,
          state.pages.selectedPage.path
        );
        const initialPage = selectedPage
          ? selectedPage.page_id
          : data[0].page_id;

        if (window.location.pathname === `/site/${siteId}/theme`) {
          if (selectedPage?.page_type === "subpage") {
            return {
              pages: data,
              selectedPage: {
                id: initialPage,
                path: [selectedPage.parent_page_id, initialPage],
              },
            };
          } else {
            return {
              pages: data,
              selectedPage: {
                id: initialPage,
                path: [initialPage],
              },
            };
          }
        }

        if (selectedPage?.page_type === "subpage") {
          const locationPath = window.location.pathname;
          if (locationPath !== `/site/${siteId}/pages`) {
            navigate(`/site/${state.auth.siteId}/${initialPage}`, {
              replace: true,
            });
          }
          return {
            pages: data,
            selectedPage: {
              id: initialPage,
              path: [selectedPage.parent_page_id, initialPage],
            },
          };
        } else {
          const locationPath = window.location.pathname;
          if (locationPath !== `/site/${siteId}/pages`) {
            navigate(`/site/${state.auth.siteId}/${initialPage}`, {
              replace: true,
            });
          }
          return {
            pages: data,
            selectedPage: {
              id: initialPage,
              path: [initialPage],
            },
          };
        }
      } else {
        if (window.location.pathname !== `/site/${siteId}`) {
          navigate(`/site/${state.auth.siteId}`, { replace: true });
        }
        return {
          pages: [],
          selectedPage: {
            id: null,
            path: [],
          },
        };
      }
    } catch (error) {
      const errorMessage = error || "An unexpected error occurred.";
      dispatch(
        showError({
          title: "Api Request Error",
          description: errorMessage,
        })
      );
      return rejectWithValue(errorMessage);
    }
  }
);

/* ----------------------------- GET SINGLE PAGE ---------------------------- */
export const getSinglePageByPageIdThunk = createAsyncThunk(
  "pages/getSinglePageByPageId",
  async (pageId, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getSinglePageByPageId(pageId);
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred.",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred.");
    }
  }
);

/* ------------------------------- UPDATE PAGE ------------------------------ */
export const updatePageByPageIdThunk = createAsyncThunk(
  "pages/updatePageByPageId",
  async (
    { urlencoded, pageId, navigate },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await apiService.updatePageByPageId(urlencoded, pageId);
      if (response?.success) {
        const auth = getState().auth;
        await dispatch(
          getAllPagesBySiteIdThunk({ siteId: auth.siteId, navigate })
        ).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An Error occured while upadting the page",
          })
        );
        return rejectWithValue(
          response?.message || "An Error occured while upadting the page"
        );
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred.",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred.");
    }
  }
);

/* -------------------------- UPDATE STATUS OF PAGE ------------------------- */
export const updateStatusOfPageThunk = createAsyncThunk(
  "pages/updateStatusOfPage",
  async (
    { pageId, navigate, urlencoded },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await apiService.updateStatusOfPage(pageId, urlencoded);
      if (response.success === true) {
        const auth = getState().auth;
        await dispatch(
          getAllPagesBySiteIdThunk({ siteId: auth.siteId, navigate })
        ).unwrap();
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message ||
              "An Error Occured while udpating status of page",
          })
        );
        return rejectWithValue(
          response?.message || "An Error Occured while udpating status of page"
        );
      }
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred.",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred.");
    }
  }
);

/* ------------------------------- DELETE PAGE ------------------------------ */
export const deletePageByPageIdThunk = createAsyncThunk(
  "pages/deletePageByPageId",
  async ({ pageId, navigate }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await apiService.deletePageByPageId(pageId);
      if (response.success === true) {
        const auth = getState().auth;
        await dispatch(
          getAllPagesBySiteIdThunk({ siteId: auth.siteId, navigate })
        ).unwrap();
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An Error Occured while deleting Page",
          })
        );
        return rejectWithValue(
          response?.message || "An Error Occured while deleting Page"
        );
      }
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred.",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred.");
    }
  }
);

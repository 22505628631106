import JSZip from "jszip";
// import { saveAs } from "file-saver";
import { publishSiteBySiteIdThunk } from "../redux/thunks";
import { exportHTMLItems, exportScriptItems } from "../extracted_html_and_css";
import { exportHeaderHTML } from "../extracted_html_and_css/widgets_html/header";
import { exportFooterHTML } from "../extracted_html_and_css/widgets_html/footer";

/* -------------------------------------------------------------------------- */
/*                             EXPORTING FUNCTIONS                            */
/* -------------------------------------------------------------------------- */
/* -------------------------- GENERATING GLOBAL CSS ------------------------- */
const generateAndExportGlobalCSS = (settings) => {
  // GENERATING AND CREATE A FILE OF GLOBAL.CSS
  const css = `
    body {
      background-color: white;
      font-family: ${settings?.globalSettings?.fontFamily};
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      background-color: white;
      height: 100%;
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    input, button, textarea, select {
  font-family: ${settings.globalSettings?.fontFamily};
}
    ${settings?.style}
    `;
  // h1 {
  //   font-family: ${settings.globalSettings.h1.fontFamily};
  // }
  return new Blob([css], { type: "text/css" });
};

/* -------------------------- GENERATING HTML FILES ------------------------- */
const generateHTMLFilesForPage = (
  pages,
  page,
  zip,
  settings,
  siteId,
  posts,
  errorCallback
) => {
  //  GENERATING HTML FILES AS WELL AS SCRIPT FILE AND IT IS RECURSIVE FUNCTION
  const getContentHtml = exportHTMLItems(page.content?.data?.content, posts, settings);
  const getHeaderHtml = exportHeaderHTML(pages, settings);
  const getFooterHtml = exportFooterHTML(pages, settings);
  const getScript = exportScriptItems(
    page.content?.data?.content,
    siteId,
    page?.page_id
  );
  const script = `
  const apiCall = async (data) => {
       try {
         const response = await fetch('https://2dzt2fu2fl.execute-api.us-east-1.amazonaws.com/prod', {
             method: "POST",
             headers: {
                'Content-Type': 'application/json', // Set the content type
            },
             body: JSON.stringify(data),
         });
         return await response.json();
       } catch(error){
         alert(error);
       }
 }
  ${getScript}
  `;
  if (page?.page_title.toLowerCase() === "index") {
    const error = new Error(
      `File ${page?.page_title.toLowerCase()} already exists Please change it`
    );
    errorCallback(error);
    return;
  }

  const fileName = createSlug(
    page?.page_title.toLowerCase() === "home" ? "index" : page.page_title
  );
  if (zip.files[fileName + ".html"]) {
    const error = new Error(
      `File ${fileName + ".html"} already exists Please change it`
    );
    errorCallback(error);
    return;
  }

  const htmlCode = `.
    <html>
      <head>
        <title>${settings?.header?.headCode}</title>
        <link rel="stylesheet" href="global.css" />
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
  <style>
    #map {
      height: 500px; 
      width: 100%;
    }
  </style>
      </head>
      <body>
        ${getHeaderHtml}
        <div style="flex:1;margin-top: ${
          settings?.header?.headerTemplate === "floating_header" ? "90px" : "0"
        }">${getContentHtml}</div>
        ${getFooterHtml}
        ${settings?.footer?.footCode}
      </body>
      ${getScript ? `<script src="${fileName}.js"></script>` : ""}
      <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>
      <script>
       var map = L.map('map').setView([51.505, -0.09], 13);
    
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    var marker = L.marker([51.505, -0.09]).addTo(map);

    marker.bindPopup("A pretty popup. <br> Easily customizable.").openPopup();
      </script>
    </html>`;
  zip.file(`${fileName}.html`, new Blob([htmlCode], { type: "text/html" }));
  getScript &&
    zip.file(`${fileName}.js`, new Blob([script], { type: "text/javascript" }));

  if (page.subpages && page.subpages.length > 0) {
    page.subpages.forEach((subPage) =>
      generateHTMLFilesForPage(
        pages,
        subPage,
        zip,
        settings,
        siteId,
        posts,
        errorCallback
      )
    );
  }
};

/* ------------------------- CREATING SLUG BY NAMES ------------------------- */
export const createSlug = (fileName) =>
  fileName.split(" ").join("-").toLowerCase();

/* --------------------------- GENERATING ZIP FILE -------------------------- */
export const exportCodeFileInZip = async (
  pages,
  siteId,
  stateDispatch,
  navigate,
  settings,
  posts,
  setShowModal,
  setError
) => {
  //   GETTING ALL THE FILES AND ZIP THEM ALL AND PUBLISH THE ZIP FILE
  const zip = new JSZip();
  const cssBlob = await generateAndExportGlobalCSS(settings);
  await zip.file("global.css", cssBlob);
  try {
    pages.forEach((page) =>
      generateHTMLFilesForPage(
        pages,
        page,
        zip,
        settings,
        siteId,
        posts,
        (error) => {
          throw error; // Throw the error to stop further processing
        }
      )
    );
    const zipBlob = await zip.generateAsync({ type: "blob" });
    // saveAs(zipBlob, `${siteId}.zip`);
    const formData = new FormData();
    formData.append("zip_file", zipBlob, `${siteId}.zip`);
    await stateDispatch(
      publishSiteBySiteIdThunk({
        siteId,
        file: formData,
        navigate,
        setShowModal,
        setError,
      })
    ).unwrap();
  } catch (error) {
    setShowModal(true);
    setError({
      error: true,
      title: error.name,
      message: `Failed to generate ZIP: ${error.message}`,
    });
  }
};

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/* ---------------------------- FIND PAGE BY PATH --------------------------- */
export const findPageByPath = (pages, path) => {
  //  GETTING ALL THE PAGES AND PATH, FIND THE CURRENT PAGE AND RETURNING IT
  let currentPages = pages;
  let currentPage = null;

  for (let i = 0; i < path?.length; i++) {
    currentPage = currentPages?.find((page) => page?.page_id === path[i]);
    if (!currentPage) {
      return null;
    }
    currentPages = currentPage?.subpages;
  }
  return currentPage;
};

/* -------------------------- FLATTEN PAGES HANDLER ------------------------- */
export const flattenPagesHandler = (pages) => {
  // ALL THE PAGES AND SUBPAGES IN ONE ARRAY AND RETURNING IT
  const result = [];

  const flatten = (pages) => {
    pages.forEach((page) => {
      result.push(page);
      if (page.subpages) {
        flatten(page.subpages);
      }
    });
  };

  flatten(pages);
  return result;
};

/* ------------------------ CHEKING OBJECTS ARE EQUAL ----------------------- */
export const deepEqual = (obj1, obj2) => {
  //CHECKING OBJECTS ARE EQUAL OR NOT
  //IF BOTH OBJECTS ARE IDENTICAL BY REFERENCE
  if (obj1 === obj2) return true;

  //IF EITHER OF THE OBJECT IS NOT AN OBJECT OR NULL
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  )
    return false;

  //GETTING OBJECT'S KEY
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  //IF NUMBER OF KEYS ARE DIFFERENCT, THE OBJECTS ARE NOT EQUAL
  if (keys1.length !== keys2.length) return false;

  //ITERATE THROUGH KEYS1 AND COMPARE VALUES RECURSIVELY
  for (const key of keys1) {
    //IF KEY IS NOT PRESENT IF KEYS2, OBJECTS ARE NOT EQUAL
    if (!keys2.includes(key)) return false;

    //RECURSIVELY CHECK THE KEYS OF VALUE
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }
  return true;
};

/* ------------------------- GETTING TIME FORMATTED ------------------------- */
export const getTimeInFormated = (comingDate) => {
  const date = new Date(comingDate.replace(" ", "T"));
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};

/* -------------------------- RETURN UPDATED FOOTER ------------------------- */
export const returnUpdatedFooter = (currentFooter, footer) => {};

/* -------------------------- RETURN UPDATED HEADER ------------------------- */
export const returnUpdatedHeader = (currentHeader, header) => {
  const updatedHeader = {
    logo: {
      image:
        header?.logo?.image ||
        currentHeader?.logo?.image ||
        defaultHeaderData.logo.image,
      url:
        header?.logo?.url ||
        currentHeader?.logo?.url ||
        defaultHeaderData.logo.url,
    },
    menu: 1,
    social: {
      facebook:
        header?.social?.facebook ||
        currentHeader?.social?.facebook ||
        defaultHeaderData.social.facebook,
      instagram:
        header?.social?.instagram ||
        currentHeader?.social?.instagram ||
        defaultHeaderData.social.instagram,
      linkedin:
        header?.social?.linkedin ||
        currentHeader?.social?.linkedin ||
        defaultHeaderData.social.linkedin,
    },
    button: {
      title:
        header?.button?.title ||
        currentHeader?.button?.title ||
        defaultHeaderData.button.title,
      linkWith:
        header?.button?.linkWith ||
        currentHeader?.button?.linkWith ||
        defaultHeaderData.button.linkWith,
      openWebAddress:
        header?.button?.openWebAddress ||
        currentHeader?.button?.openWebAddress ||
        defaultHeaderData.button.openWebAddress,
    },
  };
  return updatedHeader;
};

/* --------------------------- DEFAULT HEADER DATA -------------------------- */
export const defaultHeaderData = {
  logo: {
    image: "",
  },
  social: {
    facebook: "https://www.facebook.com",
    instagram: "https://www.instagram.com",
    linkedin: "https://www.linkedin.com",
  },
  button: {
    title: "Login",
    linkWith: "#",
    openWebAddress: "current",
  },
  secondButton: {
    title: "Register",
    linkWith: "#",
    openWebAddress: "current",
  },
};
